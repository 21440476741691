import * as React from "react";
export const InputDefaultCell = (props) => {
  const { dataItem, widthObj, setWidthObj } = props;
  const field = props.field || "";
  const grid = React.useRef(null);
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  React.useEffect(() => {
    let itemWidth = grid.current.offsetWidth;
    if (widthObj[field]?.widthValue) {
      if (widthObj[field].widthValue < itemWidth) {
        widthObj[field].widthValue = itemWidth;
        setWidthObj(widthObj);
      }
    } else {
      widthObj[field] = { widthValue: itemWidth };
      setWidthObj(widthObj);
    }
  }, []);

  return (
    <td style={props.style} className={props.className}>
      {/* <td style={{ whiteSpace: "pre-wrap" }}> */}
      <span ref={grid}>
        {dataItem?.percen?.includes(field)
          ? parseFloat(dataValue) + "%"
          : dataItem?.format?.includes(field)
          ? dataItem?.currency?.includes(field)
            ? dataValue
                ?.toFixed(0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : dataValue
                ?.toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : dataValue}
      </span>
    </td>
  );
};
