import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { getCookie } from "../../cookie";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const token = getCookie("golfToken");

  // useEffect(() => {
  //   // cookies.remove("accepts-cookies");
  //   setAccepted(false);
  // }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !token) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return (
          <>
            <Layout>
              <Component {...props} />
            </Layout>
          </>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
