import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";

const Base = (props) => {
  return (
    <div className="wrapper">
      <Header />

      <Sidebar />

      <section className="section-container">{props.children}</section>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps)(withRouter(Base));
