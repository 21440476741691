import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import { InputWrap } from "../Common/InputWrap";
import GridTable from "../gridTable/GridTable";
import moment from "moment";

const UserList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerName] = useState([
    "userId",
    "nickname",
    "localCity",
    "birthDate",
    "phone",
    "gender",
    "registerDate",
    "lastLoginDate",
  ]);
  const [headerNameKr] = useState([
    "아이디",
    "닉네임",
    "지역",
    "연령",
    "전화번호",
    "성별",
    "가입일",
    "최근 접속일",
  ]);
  const [gridColumns, setGridColumns] = useState([]);

  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);
  const [location, setLocation] = useState([]);
  const [age, setAge] = useState([]);

  const [inputKey, setInputKey] = useState([
    {
      name: "이름",
      key: "userName",
      type: "text",
    },
    {
      name: "전화번호",
      key: "phone",
      type: "text",
    },
    {
      name: "성별",
      key: "gender",
      type: "dropdown",
      data: [
        { key: "", value: "전체" },
        { key: "MALE", value: "남자" },
        { key: "FEMALE", value: "여자" },
      ],
    },
    {
      name: "정렬",
      key: "order",
      type: "dropdown",
      defaultValue: "lastLogin",
      data: [
        { key: "lastLogin", value: "최근 접속일 순" },
        { key: "recentJoin", value: "최근 가입순" },
        { key: "oldJoin", value: "오래된 가입순" },
        { key: "point", value: "포인트 많은 순" },
      ],
    },
    {
      name: "지역",
      key: "location",
      type: "dropdown",
      defaultValue: "",
      data: location,
    },
    {
      name: "연령",
      key: "ageKey",
      type: "dropdown",
      defaultValue: "0",
      data: age,
    },
    {
      name: "아이디From",
      key: "idFrom",
      type: "text",
    },
    {
      name: "아이디To",
      key: "idTo",
      type: "text",
    },
  ]);
  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  const searchApi = async () => {
    setLoading(true);
    try {
      let obj = inputKey;
      let locationArr = [{ key: "", value: "전체" }];
      let ageArr = [];
      const locationResponse = await requestGet("/api/admin/location/list");
      const ageResponse = await requestGet("/api/admin/age/list");
      if (locationResponse?.length > 0) {
        for (let i = 0; i < locationResponse.length; i++) {
          locationArr.push({
            key: locationResponse[i],
            value: locationResponse[i],
          });
        }
        setLocation(locationArr);
      } else {
        setLocation(null);
      }
      if (ageResponse?.length > 0) {
        for (let i = 0; i < ageResponse.length; i++) {
          ageArr.push({
            key: ageResponse[i].value,
            value: ageResponse[i].key,
          });
        }
        setAge(ageArr);
      } else {
        setAge(null);
      }
      obj?.map((item, i) => {
        if (item.key === "location") {
          item.data = locationArr;
        }
        if (item.key === "ageKey") {
          item.data = ageArr;
        }
        return item;
      });
      setInputKey(obj);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    searchApi();
  }, []);

  const callApi = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj[isKey[i]]) {
            params.append(isKey[i], inputObj[isKey[i]]);
          }
        }
      }
      params.append("page", 0);
      params.append("size", 2147483647);
      const res = await requestGet(`/api/admin/user/list?${params}`);
      setData(res.content);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    let arr = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        arr.push({
          ProductID: data[i].id,
          userId: data[i].userId,
          nickname: data[i].nickname,
          localCity: data[i].localCity,
          birthDate: data[i].birthDate,
          phone: data[i].phone,
          gender: data[i].gender,
          registerDate: data[i].registerDate
            ? moment(data[i].registerDate).format("YYYY-MM-DD HH:mm:ss")
            : "",
          lastLoginDate: data[i].lastLoginDate
            ? moment(data[i].lastLoginDate).format("YYYY-MM-DD HH:mm:ss")
            : "",
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <div id="headerTilteID">회원 목록</div>
                <div style={{ padding: "10px" }} />
                <InputWrap
                  keys={keys}
                  inputKey={inputKey}
                  inputObj={inputObj}
                  setInputObj={setInputObj}
                  callApi={callApi}
                />
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px" }} />
                    </div>
                    <div
                      id="tableWrapWidth"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: `${maxH}px`,
                      }}
                    >
                      <GridTable
                        editData={gridData}
                        setEditData={setGridData}
                        propsCloumn={gridColumns}
                        callApi={callApi}
                        setEditId={setEditId}
                        setIds={setIds}
                        ids={ids}
                        push="/user/detail"
                        ref={childComponentRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(UserList);
