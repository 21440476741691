import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/reducers";
import middlewares from "./middlewares/middlewares";
// import createSagaMiddleware from "redux-saga";
// import rootSaga from "./sagas";
import { updateTheme } from "./middlewares/themes.middleware.js";

import { persistedState, saveState } from "./persisted.store.js";

export default function configureStore() {
  // const sagaMiddleware = createSagaMiddleware();
  // const composeEnhancers =
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducers,
    persistedState, // second argument overrides the initial state
    applyMiddleware(...middlewares)
    // composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  // add a listener that will be invoked on any state change
  store.subscribe(() => {
    saveState(store.getState());
  });

  // Update the initial theme
  updateTheme(store.getState());
  // sagaMiddleware.run(rootSaga);
  return store;
}
