import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import GridTable from "../gridTable/GridTable";
import Slidewithfade from "../Common/Slidewithfade";
import { InputWrap } from "../Common/InputWrap";
import moment from "moment";

const ChatingDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [clubData, setClubData] = useState(null);
  const [favoriteData, setFavoriteData] = useState(null);
  const [feedData, setFeedData] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridClubData, setGridClubData] = useState([]);
  const [gridFavoriteData, setGridFavoriteData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerClubName] = useState(["userName", "message", "date"]);
  const [headerClubNameKr] = useState(["보낸사람", "내용", "날짜"]);
  const [gridClubColumns, setGridClubColumns] = useState([]);
  const [gridFavoriteColumns, setGridFavoriteColumns] = useState([]);
  const [chatcode] = useState(props.location.state.id);
  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);
  const [subTitle, setSubTitle] = useState("userreport");

  const [infoArr, setInfoArr] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [btnArr] = useState(["userreport", "contentsreport"]);

  const history = useHistory();

  const callApi = async () => {
    setLoading(true);
    try {
      const response = await requestGet(
        `/api/admin/chatting/message/${chatcode}/list`
      );
      setData(response);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chatcode) {
      callApi();
    }
  }, [chatcode]);

  useEffect(() => {
    let arr = [];
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        arr.push({
          ProductID: data[i]?.id,
          chatId: data[i]?.chatId,
          fileList1: data[i]?.fileList1,
          fileList2: data[i]?.fileList2,
          fileList3: data[i]?.fileList3,
          fileList4: data[i]?.fileList4,
          fileList5: data[i]?.fileList5,
          fileList6: data[i]?.fileList6,
          fileList7: data[i]?.fileList7,
          fileList8: data[i]?.fileList8,
          fileList9: data[i]?.fileList9,
          fileList10: data[i]?.fileList10,
          gender: data[i]?.gender,
          message: data[i]?.message,
          date: data[i]?.messageDate
            ? moment(data[i]?.messageDate).format("YYYY-MM-DD HH:mm:ss")
            : "",
          messageDate: data[i]?.messageDate,
          messageId: data[i]?.messageId,
          messageType: data[i]?.messageType,
          messageUserId: data[i]?.messageUserId,
          readUserList: data[i]?.readUserList,
          unreadCount: data[i]?.unreadCount,
          userName: data[i]?.userName,
          userProfileUrl: data[i]?.userProfileUrl,
          userToken: data[i]?.userToken,
          userUid: data[i]?.userUid,
          writeUserId: data[i]?.writeUserId,
        });
      }
    }
    setGridClubData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerClubName?.length > 0 && headerClubNameKr?.length > 0) {
      for (let i = 0; i < headerClubName?.length; i++) {
        arr.push({
          title: headerClubNameKr[i],
          field: headerClubName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridClubColumns(arr);
    }
  }, [headerClubName, headerClubNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref, subTitle]);

  const changeSubTitle = (value) => {
    setSubTitle(value);
  };

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    채팅
                  </Col>
                </Row>
                <div style={{ padding: "10px" }} />
                <Button id="trueBtn" onClick={() => history.goBack()}>
                  뒤로가기
                </Button>
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px 0px" }}></div>
                    </div>
                    <div
                      id="tableWrapWidth"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: `${maxH}px`,
                      }}
                    >
                      <GridTable
                        editData={gridClubData}
                        setEditData={setGridClubData}
                        propsCloumn={gridClubColumns}
                        callApi={callApi}
                        setEditId={setEditId}
                        setIds={setIds}
                        ids={ids}
                        ref={childComponentRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(ChatingDetail);
