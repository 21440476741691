import React, { useEffect, useState } from "react";

import { Row, Col, Container, Card } from "reactstrap";

// Redux
import { withRouter, useHistory } from "react-router-dom";

import "./login.scss";
import { loginPost } from "../../apis/request";
import { setCookie } from "../../cookie";

const Login = (props) => {
  const [id, setId] = useState("admin");
  const [pw, setPw] = useState("12345");
  const history = useHistory();

  let previous;
  let htmlContent;

  const handleValidSubmit = async (e) => {
    try {
      e.preventDefault();
      const params = new URLSearchParams();
      params.append("username", id);
      params.append("password", pw);
      const res = await loginPost(`/api/v1/login?${params}`);
      if (res.token) {
        setCookie("golfToken", res.token, {
          path: "/",
          // httpOnly: true,
        });
        localStorage.setItem("userId", res.id);
        history.push("/dashboard");
        window.location.reload();
      } else {
        alert("Login failed.");
      }
    } catch (error) {
      alert("Login failed.");
    }
  };

  const onInquire = () => {
    history.push("/contactus");
  };

  return (
    <React.Fragment>
      <div className="account-pages m-0 p-0" id="loginPageWrap">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <Card
                className="overflow-hidden"
                style={{ border: "none", backgroundColor: "#373737" }}
              >
                <Row className="d-flex justify-content-center g-0">
                  <Col lg={6}>
                    <div className="px-lg-5 pb-lg-p5 px-4 pb-0 pt-0">
                      <div className="mt-4 pt-4">
                        <form
                          className="form-horizontal"
                          onSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          <div className="mb-4">
                            <h3 style={{ color: "#fff" }}>Username</h3>
                            <input
                              name="email"
                              label="Username"
                              className="loginInputText form-control"
                              placeholder="Enter Username."
                              type="text"
                              value={id}
                              onChange={(e) => setId(e.target.value)}
                              required
                            />
                          </div>

                          <div className="mb-4">
                            <h3 style={{ color: "#fff" }}>Password</h3>
                            <input
                              name="password"
                              label="Password"
                              type="password"
                              className="loginInputText form-control"
                              value={pw}
                              onChange={(e) => setPw(e.target.value)}
                              required
                              placeholder="Enter Password."
                            />
                          </div>
                          <div className="m-0">
                            <button
                              id="loginButton"
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              style={{
                                backgroundColor: "#FF5913",
                                border: "1px solid #FF5913",
                              }}
                            >
                              LOGIN
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

// Login.propTypes = {
//   error: PropTypes.any,
//   history: PropTypes.object,
//   loginUser: PropTypes.func,
//   socialLogin: PropTypes.func,
// };
