import React from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
import Adminmiddleware from "./routes/middleware/Adminmiddleware";
import NonAuthLayout from "./pages/header/NonAuthLayout";
import Base from "./pages/header/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@progress/kendo-theme-default/dist/all.css";
// import "./styles/bootstrap.scss";
// import "./styles/app.scss";
import "./assets/scss/theme.scss";

const App = (props) => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {userRoutes.map((route, idx) => (
            <Adminmiddleware
              path={route.path}
              layout={Base}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
