// Authentication related pages
import Login from "../pages/login/Login";
import SignUp from "../pages/login/SignUp";
// Dashboard
import Dashboard from "../pages/dashboard/Dashboard";
import UserList from "../pages/page/UserList";
import UserDetail from "../pages/page/UserDetail";
import MainPageControl from "../pages/page/MainPageControl";
import ProfileAccept from "../pages/page/ProfileAccept";
import VersionControl from "../pages/page/VersionControl";
import MapPage from "../pages/page/MapPage";
import PeedList from "../pages/page/PeedList";
import FeedDetail from "../pages/page/FeedDetail";
import BannerUpload from "../pages/page/BannerUpload";
import BannerList from "../pages/page/BannerList";
import BannerDetail from "../pages/page/BannerDetail";
import PushPost from "../pages/page/PushPost";
import ProfileDetail from "../pages/page/ProfileDetail";
import NoticeList from "../pages/page/NoticeList";
import NoticeUpload from "../pages/page/NoticeUpload";
import NoticeDetail from "../pages/page/NoticeDetail";
import SystemSetting from "../pages/page/SystemSetting";
import ClubList from "../pages/page/ClubList";
import ClubDetail from "../pages/page/ClubDetail";
import RecommandUser from "../pages/page/RecommandUser";
import ReportUser from "../pages/page/ReportUser";
import Chating from "../pages/page/Chating";
import ChatingDetail from "../pages/page/ChatingDetail";
import Recommendskeyword from "../pages/page/Recommendskeyword";

// eslint-disable-next-line no-sparse-arrays
const userRoutes = [
  {
    path: "/push/post",
    component: PushPost,
  },
  {
    path: "/banner/detail",
    component: BannerDetail,
  },
  {
    path: "/banner/list",
    component: BannerList,
  },
  {
    path: "/banner/upload",
    component: BannerUpload,
  },
  {
    path: "/notice/list",
    component: NoticeList,
  },
  {
    path: "/notice/upload",
    component: NoticeUpload,
  },
  {
    path: "/notice/detail",
    component: NoticeDetail,
  },
  {
    path: "/club/list",
    component: ClubList,
  },
  {
    path: "/club/detail",
    component: ClubDetail,
  },
  {
    path: "/user/list",
    component: UserList,
  },
  {
    path: "/user/recommand",
    component: RecommandUser,
  },
  {
    path: "/user/report",
    component: ReportUser,
  },
  {
    path: "/user/detail",
    component: UserDetail,
  },
  {
    path: "/feed/list",
    component: PeedList,
  },
  {
    path: "/feed/detail",
    component: FeedDetail,
  },
  {
    path: "/main/con",
    component: MainPageControl,
  },
  {
    path: "/profile",
    component: ProfileAccept,
  },
  {
    path: "/profile/detail",
    component: ProfileDetail,
  },
  {
    path: "/user/map",
    component: MapPage,
  },
  {
    path: "/version/con",
    component: VersionControl,
  },
  {
    path: "/service",
    component: SystemSetting,
  },
  {
    path: "/chating",
    component: Chating,
  },
  {
    path: "/chating/detail",
    component: ChatingDetail,
  },
  {
    path: "/recommends/keyword",
    component: Recommendskeyword,
  },
  { path: "/dashboard", component: Dashboard },
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: SignUp },
];

export { userRoutes, authRoutes };
