import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import GridTable from "../gridTable/GridTable";
import Slidewithfade from "../Common/Slidewithfade";

const ClubDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerName] = useState([
    // "thumbnail",
    // "name",
    "contents",
    // "memberCount",
    // "ownerName",
  ]);
  const [headerNameKr] = useState([
    // "썸네일",
    // "이름",
    "내용",
    // "회원수",
    // "개설자",
  ]);
  const [gridColumns, setGridColumns] = useState([]);
  const [userId] = useState(props.location.state.id);
  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);

  const callApi = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      params.append("userId", userId);
      const response = await requestGet(`/api/admin/club/feed/${userId}`);
      setData(response);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      callApi();
    }
  }, [userId]);

  useEffect(() => {
    let arr = [];
    if (data) {
      for (let i = 0; i < data?.length; i++) {
        arr.push({
          allowComment: data[i].allowComment,
          clubId: data[i].clubId,
          comments: data[i].comments,
          contents: data[i].contents,
          createdAt: data[i].createdAt,
          feedKind: data[i].feedKind,
          id: data[i].id,
          media: data[i].media,
          notice: data[i].notice,
          privacySetting: data[i].privacySetting,
          tags: data[i].tags,
          title: data[i].title,
          userProfile: data[i].userProfile,
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    클럽 상세보기
                  </Col>
                </Row>
                <div style={{ padding: "10px" }} />
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px" }} />
                    </div>
                    <div
                      id="tableWrapWidth"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: `${maxH}px`,
                      }}
                    >
                      {gridData ? (
                        <GridTable
                          editData={gridData}
                          setEditData={setGridData}
                          propsCloumn={gridColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(ClubDetail);
