import React, { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import "./mastermanagement.scss";
import { requestGet, requestPost } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { InputWrap } from "../Common/InputWrap";
import GridTable from "../gridTable/GridTable";
import QueryString from "qs";

const PushPost = () => {
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState("");
  const [date, setDate] = useState("");
  const [targetDate, setTargetDate] = useState("");
  const [targetHour, setTargetHour] = useState("");
  const [targetMinute, setTargetMinute] = useState("");
  const [dateDropdown, setDateDropdown] = useState("false");
  const [userDropdown, setUserDropdown] = useState("false");
  const [url, setUrl] = useState("");
  const history = useHistory();

  const [location, setLocation] = useState([]);
  const [age, setAge] = useState([]);

  const [inputKey, setInputKey] = useState([
    {
      name: "이름",
      key: "userName",
      type: "text",
    },
    {
      name: "전화번호",
      key: "phone",
      type: "text",
    },
    {
      name: "성별",
      key: "gender",
      type: "dropdown",
      data: [
        { key: "", value: "전체" },
        { key: "MALE", value: "남자" },
        { key: "FEMALE", value: "여자" },
      ],
    },
    {
      name: "지역",
      key: "location",
      type: "dropdown",
      defaultValue: "",
      data: location,
    },
    {
      name: "연령",
      key: "ageKey",
      type: "dropdown",
      defaultValue: "0",
      data: age,
    },
    {
      name: "아이디From",
      key: "idFrom",
      type: "text",
    },
    {
      name: "아이디To",
      key: "idTo",
      type: "text",
    },
  ]);
  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);
  const [data, setData] = useState([]);
  const [headerName] = useState([
    "userId",
    "nickname",
    "localCity",
    "birthDate",
    "phone",
    "gender",
    "registerDate",
    "lastLoginDate",
  ]);
  const [headerNameKr] = useState([
    "아이디",
    "닉네임",
    "지역",
    "연령",
    "전화번호",
    "성별",
    "가입일",
    "최근 접속일",
  ]);
  const [gridColumns, setGridColumns] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [paramsPrev, setParamsPrev] = useState(null);
  const childComponentRef = useRef();

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  const searchApi = async () => {
    setLoading(true);
    try {
      let obj = inputKey;
      let locationArr = [{ key: "", value: "전체" }];
      let ageArr = [];
      const locationResponse = await requestGet("/api/admin/location/list");
      const ageResponse = await requestGet("/api/admin/age/list");
      if (locationResponse?.length > 0) {
        for (let i = 0; i < locationResponse.length; i++) {
          locationArr.push({
            key: locationResponse[i],
            value: locationResponse[i],
          });
        }
        setLocation(locationArr);
      } else {
        setLocation(null);
      }
      if (ageResponse?.length > 0) {
        for (let i = 0; i < ageResponse.length; i++) {
          ageArr.push({
            key: ageResponse[i].value,
            value: ageResponse[i].key,
          });
        }
        setAge(ageArr);
      } else {
        setAge(null);
      }
      obj?.map((item, i) => {
        if (item.key === "location") {
          item.data = locationArr;
        }
        if (item.key === "ageKey") {
          item.data = ageArr;
        }
        return item;
      });
      setInputKey(obj);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    searchApi();
  }, []);

  const callApi = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj[isKey[i]]) {
            params.append(isKey[i], inputObj[isKey[i]]);
          }
        }
      }
      params.append("page", 0);
      params.append("size", 2147483647);
      setParamsPrev(params);
      const res = await requestGet(`/api/admin/user/list?${params}`);
      setData(res.content);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    let arr = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        arr.push({
          ProductID: data[i].id,
          userId: data[i].userId,
          nickname: data[i].nickname,
          localCity: data[i].localCity,
          birthDate: data[i].birthDate,
          phone: data[i].phone,
          gender: data[i].gender,
          registerDate: data[i].registerDate
            ? moment(data[i].registerDate).format("YYYY-MM-DD HH:mm:ss")
            : "",
          lastLoginDate: data[i].lastLoginDate
            ? moment(data[i].lastLoginDate).format("YYYY-MM-DD HH:mm:ss")
            : "",
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  const onSubmitApi = async (e) => {
    if (window.confirm("메세지를 보내시겠습니까?")) {
      try {
        setLoading(true);
        const params =
          userDropdown === "true" && gridData?.length > 0
            ? new URLSearchParams(`${paramsPrev}`)
            : new URLSearchParams();
        if (contents) {
          params.append("message", contents);
        }
        if (url) {
          params.append("url", url);
        }
        if (
          targetDate &&
          targetDate !== "Invalid date" &&
          dateDropdown === "true"
        ) {
          params.append("targetDate", targetDate);
        }
        if (
          targetHour &&
          targetHour !== "Invalid date" &&
          dateDropdown === "true"
        ) {
          params.append("targetHour", targetHour);
        }
        if (
          targetMinute &&
          targetMinute !== "Invalid date" &&
          dateDropdown === "true"
        ) {
          params.append("targetMinute", targetMinute);
        }
        if (userDropdown === "true") {
          params.append("userIdList", ids);
        }
        params.append("isAll", userDropdown === "false" ? true : false);
        let data = params;
        await requestPost("/api/admin/push/send", data);
        setLoading(false);
        alert("등록하였습니다.");
        window.location.reload();
      } catch (error) {
        setLoading(false);
        alert("등록 실패 하였습니다.");
      }
    }
  };

  const onContents = (e) => {
    e.preventDefault();
    setContents(e.target.value);
  };

  const handleDate = (e) => {
    setDate(moment(e.target.value).format("YYYY-MM-DD HH:mm"));
    setTargetDate(moment(e.target.value).format("YYYYMMDD"));
    setTargetHour(moment(e.target.value).format("HH"));
    setTargetMinute(moment(e.target.value).format("mm"));
  };

  const onURL = (e) => {
    e.preventDefault();
    setUrl(e.target.value);
  };

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container fluid>
        <Row className="w-100 m-0 d-flex justify-content-center">
          <Col className="col-12 px-5">
            <Row className="m-0">
              <Row className="w-100 mx-0 my-0 p-0">
                <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                  푸시 메세지 보내기
                </Col>
              </Row>
              <div style={{ padding: "10px" }} />
            </Row>
            <Row className="w-100 m-0 mb-4">
              <Col>
                <Row>
                  <Col className="p-0">
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          메세지 내용
                          <Input
                            type="textarea"
                            rows="3"
                            style={{ resize: "none" }}
                            value={contents}
                            onChange={onContents}
                          />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          URL
                          <Input type="text" value={url} onChange={onURL} />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          날짜
                          <select
                            className="form-select"
                            onChange={(e) => setDateDropdown(e.target.value)}
                          >
                            <option value={"false"}>즉시</option>
                            <option value={"true"}>날짜 선택</option>
                          </select>
                        </Label>
                      </Col>
                    </Row>
                    {dateDropdown === "true" ? (
                      <Row>
                        <Col>
                          <Label
                            style={{
                              color: "#000",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            <input
                              className="form-control"
                              type="datetime-local"
                              value={moment(date).format("YYYY-MM-DD HH:mm")}
                              onChange={handleDate}
                              min={moment().format("YYYY-MM-DD HH:mm")}
                              max={"9999-12-31 23:59"}
                            />
                          </Label>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <Col>
                        <Label
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          회원
                          <select
                            className="form-select"
                            onChange={(e) => setUserDropdown(e.target.value)}
                          >
                            <option value={"false"}>전체 회원</option>
                            <option value={"true"}>선택 회원</option>
                          </select>
                        </Label>
                      </Col>
                    </Row>
                    <div
                      style={
                        userDropdown === "true"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div style={{ margin: "5px 0px 10px" }}>
                        <InputWrap
                          keys={keys}
                          inputKey={inputKey}
                          inputObj={inputObj}
                          setInputObj={setInputObj}
                          callApi={callApi}
                        />
                      </div>
                      <GridTable
                        editData={gridData}
                        setEditData={setGridData}
                        propsCloumn={gridColumns}
                        callApi={callApi}
                        setEditId={setEditId}
                        setIds={setIds}
                        ids={ids}
                        ref={childComponentRef}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "auto",
                        marginBottom: "10px",
                      }}
                    >
                      <Col xs="auto" className="d-flex align-items-end">
                        <Button
                          className="acceptBtn"
                          onClick={() => onSubmitApi()}
                        >
                          등록하기
                        </Button>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(PushPost);
