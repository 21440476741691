import {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import "realgrid/dist/realgrid-style.css";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
  getSelectedState,
  GridColumnMenuSort,
  GridColumnMenuCheckboxFilter,
} from "@progress/kendo-react-grid";
// import { ExcelExport } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import { MyCommandCell } from "./myCommandCell";
import { classNames, getter } from "@progress/kendo-react-common";
import CalculateFontSize from "../../components/Common/CalculateFontSize";
import moment from "moment";
import { DefaultCell } from "./DefaultCell";

const GridTable = forwardRef(
  (
    {
      editData,
      setEditData,
      propsCloumn,
      callApi,
      setIds,
      exceldown,
      excelData,
      shop,
      push,
      delApi,
      deleteapi,
      addapi,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      // 부모 컴포넌트에서 사용할 함수를 선언
      addNew,
      removeIds,
      removeID,
    }));

    const [data, setData] = useState(
      editData?.map((dataItem) =>
        Object.assign(
          {
            selected: false,
          },
          dataItem
        )
      )
    );
    const initialDataState = {
      skip: 0,
      take: 25,
    };
    const [currentColumns, setCurrentColumns] = useState(propsCloumn);
    const [selectedState, setSelectedState] = useState({});
    const [columns, setColumns] = useState(propsCloumn);
    const [columnsExpanded, setColumnsExpanded] = useState(false);
    const [filterExpanded, setFilterExpanded] = useState(false);
    const [inputTextGrid, setinputTextGrid] = useState({ idnrk: "" });
    const [widthObj, setWidthObj] = useState({});
    const [gridWidth, setGridWidth] = useState(0);
    const [take] = useState(25);
    const grid = useRef(null);
    const tableWidth = useRef(null);
    const DATA_ITEM_KEY = "ProductID";
    const SELECTED_FIELD = "selected";
    const editField = "inEdit";
    const idGetter = getter(DATA_ITEM_KEY);
    const timeout = useRef(null);
    const inputRef = useRef(null);
    const _export = useRef(null);
    const [result, setResult] = useState(
      process(editData, { skip: 0, take: 25 })
    );
    const [dataState, setDataState] = useState({});
    const excelExport = () => {
      if (excelData.length > 0) {
        if (_export.current !== null) {
          _export.current.save();
        } else {
          alert("데이터가 없습니다.");
        }
      }
    };
    const dataStateChange = (event) => {
      setResult(process(editData, event.dataState));
      setDataState(event.dataState);
    };
    const setPercentage = (percentage) => {
      return Math.round(gridWidth / 100) * percentage;
    };

    const insertItem = (item) => {
      let el = data;
      item.ProductID = el?.length;
      item.inEdit = false;
      setData(el.unshift(item));
      return data;
    };

    const setWidth = (field, title) => {
      let maxWidth = getTextWidth(title, "normal 16px Arial") + 60;
      result.data?.forEach((item, i) => {
        // const titleSize = getTextWidth(title, "normal 16px Arial") + 60;
        const size = getTextWidth(item[field], "normal 16px Arial") + 60;
        // if (i === 0) {
        //   maxWidth = titleSize;
        // }
        if (size > maxWidth) {
          maxWidth = size;
        }
      });
      return maxWidth;
    };

    function getTextWidth(text, font) {
      const canvas =
        getTextWidth.canvas ||
        (getTextWidth.canvas = document.createElement("canvas"));
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }

    // useEffect(() => {
    //   if (tableWidth.current?.offsetWidth) {
    //     let arr = [];
    //     let diff = gridWidth - tableWidth.current.offsetWidth;
    //     let defualtVal = Math.round(gridWidth / 100) * 10;
    //     let diffval = Math.round(diff / defualtVal);
    //     for (let i = 0; i < diffval; i++) {
    //       arr.push({
    //         title: "",
    //         field: "",
    //         locked: false,
    //         hidden: false,
    //         show: true,
    //         filter: "text",
    //         width: 150,
    //       });
    //     }
    //     setColumns([...columns, ...arr]);
    //   }
    // }, [gridWidth, tableWidth, data]);

    useEffect(() => {
      let arr = [];
      Object.keys(selectedState)?.map((entrie, idx) => {
        if (selectedState[entrie]) {
          arr.push(entrie);
        }
      });
      setIds(arr);
    }, [selectedState]);

    const getItems = () => {
      let el = data;
      return el;
    };
    const updateItem = (item) => {
      let el = data;
      el?.map((map_item, i) => {
        if (map_item.ProductID === item.ProductID) {
          map_item = item;
          map_item.inEdit = false;
          return map_item;
        }
        return map_item;
      });
      return el;
    };

    const deleteItem = (item) => {
      let index = data.findIndex(
        (record) => record.ProductID === item.ProductID
      );
      data.splice(index, 1);
      return data;
    };

    const handleReSize = () => {
      setGridWidth(grid.current.offsetWidth);
    };

    useEffect(() => {
      tableWidth.current = document.querySelector(".k-grid-table");
      setGridWidth(grid.current.offsetWidth);
      let newItems = getItems();
      setData(newItems);
      window.addEventListener("resize", handleReSize);
      return () => {
        window.removeEventListener("resize", handleReSize);
      };
    }, []);

    // modify the data in the store, db etc
    const remove = (dataItem) => {
      const newData = [...deleteItem(dataItem)];
      setData(newData);
    };

    const isNullFilter = (element) => {
      if (element.ProductID) {
        return true;
      }
    };

    const add = (dataItem) => {
      dataItem.inEdit = true;
      const newData = insertItem(dataItem);
      let filterArr = newData.filter(isNullFilter);
      setData(
        filterArr?.map((item) =>
          item.ProductID === dataItem.ProductID
            ? {
                ...item,
                inEdit: false,
              }
            : item
        )
      );
    };

    const update = (dataItem) => {
      dataItem.inEdit = false;
      const newData = updateItem(dataItem);
      setData(
        newData?.map((item) =>
          item.ProductID === dataItem.ProductID
            ? {
                ...item,
                inEdit: false,
              }
            : item
        )
      );
    };

    // Local state operations
    const discard = () => {
      const newData = [...data];
      newData.splice(0, 1);
      setData(newData);
    };
    const cancel = (dataItem) => {
      const originalItem = getItems().find(
        (p) => p.ProductID === dataItem.ProductID
      );
      const newData = data?.map((item) =>
        item.ProductID === originalItem.ProductID ? originalItem : item
      );
      setData(
        newData?.map((item) =>
          item.ProductID === dataItem.ProductID
            ? {
                ...item,
                inEdit: false,
              }
            : item
        )
      );
    };

    const enterEdit = (dataItem) => {
      setData(
        data?.map((item) =>
          item.ProductID === dataItem.ProductID
            ? {
                ...item,
                inEdit: true,
              }
            : item
        )
      );
    };

    const addNew = () => {
      let obj = {};
      for (let i = 0; i < currentColumns.length; i++) {
        if (currentColumns[i]["field"] === "fd14branchsales") {
          obj[currentColumns[i]["field"]] = "InQ";
        } else {
          obj[currentColumns[i]["field"]] = "";
        }
      }
      if (editData[0]?.ProductID) {
        obj.ProductID =
          moment().format("YYYY-MM-DD HH:mm:ss") + editData?.length;
      } else {
        obj.ProductID =
          moment().format("YYYY-MM-DD HH:mm:ss") + editData?.length;
      }
      obj.addrowEdit = true;
      const newDataItem = obj;
      setEditData([newDataItem, ...editData]);
      setData([newDataItem, ...data]);
    };

    const removeID = (ids) => {
      let obj = selectedState;
      for (let i = 0; i < ids?.length; i++) {
        obj[ids[i]] = false;
      }
      const newData = data?.map((item) =>
        ids.includes(item.ProductID)
          ? {
              ...item,
              [SELECTED_FIELD]: false,
            }
          : item
      );
      setSelectedState(obj);
      setData(newData);
    };

    const removeIds = () => {
      setSelectedState({});
      setIds([]);
    };

    const CommandCell = (props) => (
      <MyCommandCell
        {...props}
        edit={enterEdit}
        remove={remove}
        add={add}
        discard={discard}
        update={update}
        cancel={cancel}
        editField={editField}
      />
    );

    const MyDefaultCell = (props) => (
      <DefaultCell
        {...props}
        edit={enterEdit}
        remove={remove}
        add={add}
        discard={discard}
        update={update}
        cancel={cancel}
        editField={editField}
        push={push}
        delApi={delApi}
        deleteapi={deleteapi}
        addapi={addapi}
      />
    );

    useLayoutEffect(() => {
      if (inputRef.current !== null) inputRef.current.focus();
    });
    const onColumnsSubmit = (columnsState) => {
      setColumns(columnsState);
    };

    useEffect(() => {
      if (data?.length > 0) {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
          callApi();
        }, 500);
      }
    }, [inputTextGrid]);

    const handleColumnLockToggle = (columnField, state) => {
      let newColumns = currentColumns?.map((column) => {
        if (column.field === columnField) {
          column.locked = state;
        }

        return column;
      });
      setCurrentColumns(newColumns);
    };

    const handleColumnHideToggle = (columnField, state) => {
      let newColumns = currentColumns?.map((column) => {
        if (column.field === columnField) {
          column.hidden = state;
        }

        return column;
      });
      setCurrentColumns(newColumns);
    }; // place all locked columns first

    useEffect(() => {
      setResult(process(editData, { skip: 0, take: 25 }));
      setCurrentColumns(propsCloumn);
      setColumns(propsCloumn);
    }, [editData, propsCloumn]);

    const onSelectionChange = (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    };

    const onHeaderSelectionChange = (event) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {};
      const oldSelectedState = selectedState;
      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState({ ...selectedState, ...newSelectedState });
    };

    //--------

    const CustomColumnMenu = (props) => {
      const oneVisibleColumn = columns.filter((c) => c.show)?.length === 1;
      const onToggleColumn = (id) => {
        const newColumns = columns?.map((column, idx) => {
          return idx === id ? { ...column, show: !column.show } : column;
        });
        setColumns(newColumns);
      };

      const handleColumnToggle = (state) => {
        props.onColumnLockToggle(props.column.field || "", state);

        if (props.onCloseMenu) {
          props.onCloseMenu();
        }
      };

      const onMenuItemClick = () => {
        const value = !columnsExpanded;
        setColumnsExpanded(value);
        setFilterExpanded(value ? false : filterExpanded);
      };

      const onSubmit = (event) => {
        if (event) {
          event.preventDefault();
        }

        onColumnsSubmit(columns);

        if (props.onCloseMenu) {
          props.onCloseMenu();
        }
      };

      const onReset = (event) => {
        event.preventDefault();
        const newColumns = propsCloumn?.map((col) => {
          return { ...col, show: true };
        });
        setColumns(newColumns);
        onColumnsSubmit(newColumns);

        if (props.onCloseMenu) {
          props.onCloseMenu();
        }
      };

      return (
        <div>
          <GridColumnMenuItemGroup>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuItemGroup>
              <GridColumnMenuItem
                title={"Columns"}
                iconClass={"k-i-columns"}
                onClick={onMenuItemClick}
              />
              <GridColumnMenuItemContent show={columnsExpanded}>
                <div className={"k-column-list-wrapper"}>
                  <form onSubmit={onSubmit} onReset={onReset}>
                    <div className={"k-column-list"}>
                      {columns.map((column, idx) => (
                        <div key={idx} className={"k-column-list-item"}>
                          <span>
                            <input
                              id={`column-visiblity-show-${idx}`}
                              className="k-checkbox k-checkbox-md k-rounded-md"
                              type="checkbox"
                              readOnly={true}
                              disabled={column.show && oneVisibleColumn}
                              checked={column.show}
                              onClick={() => {
                                onToggleColumn(idx);
                              }}
                            />
                            <label
                              htmlFor={`column-visiblity-show-${idx}`}
                              className="k-checkbox-label"
                              style={{
                                userSelect: "none",
                              }}
                            >
                              {column.title}
                            </label>
                          </span>
                        </div>
                      ))}
                    </div>
                    <div
                      className={"k-actions k-hstack k-justify-content-stretch"}
                    >
                      <button
                        type={"reset"}
                        className={
                          "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        }
                      >
                        Reset
                      </button>
                      <button
                        className={
                          "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        }
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </GridColumnMenuItemContent>
            </GridColumnMenuItemGroup>

            <GridColumnMenuCheckboxFilter {...props} data={editData} />

            <GridColumnMenuItemContent show={true}>
              <div className={"k-column-list-wrapper"}>
                <div className={"k-column-list"}>
                  <div
                    className={classNames("k-column-list-item", {
                      "k-disabled": props.locked,
                    })}
                    onClick={() => handleColumnToggle(true)}
                  >
                    <span className="k-icon k-i-lock" /> Lock Column
                  </div>
                  <div
                    className={classNames("k-column-list-item", {
                      "k-disabled": !props.locked,
                    })}
                    onClick={() => handleColumnToggle(false)}
                  >
                    <span className="k-icon k-i-unlock" /> Unlock Column
                  </div>
                </div>
              </div>
            </GridColumnMenuItemContent>
          </GridColumnMenuItemGroup>
        </div>
      );
    };
    return (
      <div
        className="w-100 kendogridWrap"
        ref={grid}
        style={shop ? null : { height: "100%" }}
      >
        {/* <ExcelExport data={excelData} ref={_export}> */}
        <Grid
          resizable={true}
          reorderable={true}
          filterable={false}
          sortable={true}
          pageable={{
            buttonCount: 5,
            type: "numeric",
            info: true,
            previousNext: true,
            pageSizes: [25, 50, 100],
          }}
          take={take}
          skip={0}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          data={{
            data: result.data?.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            })),
            total: result.total,
          }}
          onDataStateChange={dataStateChange}
          {...dataState}
        >
          {exceldown ? (
            <GridToolbar>
              <button
                title="Export Excel"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={excelExport}
              >
                구글시트 연동
              </button>
            </GridToolbar>
          ) : null}
          {columns?.map(
            (column, idx) =>
              column.show &&
              (column.field === "inEdit" ? (
                <Column cell={CommandCell} width="200px" />
              ) : column.field === "selected" ? (
                <Column
                  key={idx}
                  field={SELECTED_FIELD}
                  title={column.title}
                  filter={column.filter}
                  hidden={column.hidden}
                  locked={true}
                  resizable={false}
                  width="42px"
                  headerSelectionValue={
                    result.data?.findIndex(
                      (item) => !selectedState[idGetter(item)]
                    ) === -1
                  }
                />
              ) : (
                <Column
                  key={idx}
                  field={column.field}
                  title={column.title}
                  filter={column.filter}
                  hidden={column.hidden}
                  locked={column.locked}
                  // width={setWidth(column.field, column.minWidth)}
                  width={setWidth(column.field, column.title)}
                  encoded={false}
                  // width={column.width}
                  // width={setPercentage(10)}
                  cell={MyDefaultCell}
                  columnMenu={(props) => (
                    <CustomColumnMenu
                      {...props}
                      onColumnLockToggle={handleColumnLockToggle}
                      onColumnHideToggle={handleColumnHideToggle}
                      locked={column.locked || false}
                    />
                  )}
                />
              ))
          )}
        </Grid>
        {/* </ExcelExport> */}
        <br />
      </div>
    );
  }
);

export default GridTable;
