import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { adminRequestPost, requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const MainPageControl = () => {
  const [loading, setLoading] = useState(false);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const [buttons, setButtons] = useState([
    { name: "메인", btns: ["bookingPrentShow"] },
    // {
    //   name: "부킹",
    //   btns: [
    //     "bookingFieldShow",
    //     "bookingScreenShow",
    //     "bookingPackageShow",
    //     "bookingEmergencyShow",
    //   ],
    // },
    // {
    //   name: "조인",
    //   btns: [
    //     "joinFieldShow",
    //     "joinScreenShow",
    //     "joinPackageShow",
    //     "joinEmergencyShow",
    //   ],
    // },
    { name: "추천 골프친구", btns: ["recommendationFriendShow"] },
    { name: "공지/광고", btns: ["bannerShow"] },
    { name: "스크린", btns: ["screenShow"] },
    { name: "투어", btns: ["tourShow"] },
    { name: "쇼핑", btns: ["shoppingShow"] },
    { name: "익명 게시판", btns: ["recentAnonymousBoardShow"] },
    { name: "최근 피드", btns: ["recentWriterShow"] },
  ]);
  const [onOff, setOnOff] = useState({
    bookingPrentShow: null,
    bookingFieldShow: null,
    bookingScreenShow: null,
    bookingPackageShow: null,
    bookingEmergencyShow: null,
    joinFieldShow: null,
    joinScreenShow: null,
    joinPackageShow: null,
    joinEmergencyShow: null,
    recommendationFriendShow: null,
    bannerShow: null,
    screenShow: null,
    tourShow: null,
    shoppingShow: null,
    recentAnonymousBoardShow: null,
    recentWriterShow: null,
  });
  const [changeBtn, setChangeBtn] = useState(null);

  const callApi = async () => {
    setLoading(true);
    try {
      const res = await requestGet(`/api/anonymous/app/info/control/home`);
      setOnOff(res);
      setLoading(false);
    } catch (error) {
      setOnOff({
        bookingPrentShow: null,
        bookingFieldShow: null,
        bookingScreenShow: null,
        bookingPackageShow: null,
        bookingEmergencyShow: null,
        joinFieldShow: null,
        joinScreenShow: null,
        joinPackageShow: null,
        joinEmergencyShow: null,
        recommendationFriendShow: null,
        bannerShow: null,
        screenShow: null,
        tourShow: null,
        shoppingShow: null,
        recentAnonymousBoardShow: null,
        recentWriterShow: null,
      });
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  const callSubmit = async () => {
    if (changeBtn) {
      setLoading(true);
      try {
        let body = changeBtn;
        await adminRequestPost(`/api/admin/update/show/home`, body);
        callApi();
        setLoading(false);
      } catch (error) {
        alert("잠시후 다시 실행해 주세요.");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  const clickHandle = (btn, value) => {
    setOnOff((prevState) => ({
      ...prevState,
      [`${btn}`]: value,
    }));
    setChangeBtn((prevS) => ({
      ...prevS,
      [`${btn}`]: value,
    }));
  };

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                {/* <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    메인화면 제어
                  </Col>
                </Row> */}
                {/* <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="w-100 m-0 p-0 text-end">
                    <Button color="primary" onClick={() => callSubmit()}>
                      Submit
                    </Button>
                  </Col>
                </Row> */}
                <div id="headerTilteID">메인화면 제어</div>
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <Row>
                      <Col>
                        {buttons?.map((item, i) => {
                          return (
                            <Row
                              key={i}
                              style={{
                                padding: "10px 0px",
                                borderBottom: "1px solid #e4e4e4",
                              }}
                            >
                              <Col
                                xs="12"
                                style={
                                  i === 0
                                    ? {
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }
                                    : null
                                }
                              >
                                <h4>{item.name}</h4>
                                {i === 0 ? (
                                  <Button
                                    color="primary"
                                    onClick={() => callSubmit()}
                                  >
                                    Submit
                                  </Button>
                                ) : null}
                              </Col>
                              {item.btns?.map((el, j) => {
                                return (
                                  <Col
                                    key={j}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {item.name === "부킹" ||
                                    item.name === "조인" ? (
                                      <label>
                                        {el === "bookingFieldShow" ||
                                        el === "joinFieldShow"
                                          ? "필드"
                                          : el === "bookingScreenShow" ||
                                            el === "joinScreenShow"
                                          ? "스크린"
                                          : el === "bookingPackageShow" ||
                                            el === "joinPackageShow"
                                          ? "패키지"
                                          : el === "bookingEmergencyShow" ||
                                            el === "joinEmergencyShow"
                                          ? "긴급"
                                          : null}
                                      </label>
                                    ) : null}
                                    <ToggleButtonGroup
                                      type="checkbox"
                                      value={onOff[el]}
                                    >
                                      <ToggleButton
                                        id="tbg-btn-1"
                                        value={1}
                                        style={{ marginBottom: "0px" }}
                                        onClick={() => clickHandle(el, 1)}
                                      >
                                        On
                                      </ToggleButton>
                                      <ToggleButton
                                        id="tbg-btn-2"
                                        value={0}
                                        style={{ marginBottom: "0px" }}
                                        onClick={() => clickHandle(el, 0)}
                                      >
                                        Off
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </Col>
                                );
                              })}
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(MainPageControl);
