import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import GridTable from "../gridTable/GridTable";
import Slidewithfade from "../Common/Slidewithfade";
import moment from "moment";

const UserDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [clubData, setClubData] = useState(null);
  const [favoriteData, setFavoriteData] = useState(null);
  const [bookmarkData, setBookmarkData] = useState(null);
  const [writeData, setWriteData] = useState(null);
  const [commentedData, setCommentedData] = useState(null);
  const [feedData, setFeedData] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridClubData, setGridClubData] = useState(null);
  const [gridFavoriteData, setGridFavoriteData] = useState(null);
  const [gridFeedData, setGridFeedData] = useState(null);
  const [gridBookmarkData, setGridBookmarkData] = useState(null);
  const [gridCommentedData, setGridCommentedData] = useState(null);
  const [gridWriteData, setGridWriteData] = useState(null);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerClubName] = useState([
    "thumbnail",
    "name",
    "description",
    "memberCount",
    "ownerName",
  ]);
  const [headerClubNameKr] = useState([
    "썸네일",
    "이름",
    "설명",
    "회원수",
    "개설자",
  ]);
  const [headerFavoriteName, setHeaderFavoriteName] = useState([
    "clubName",
    "clubType",
    "sido",
    "sigun",
    "dongmyun",
  ]);
  const [headerFavoriteNameKr, setHeaderFavoriteNameKr] = useState([
    "클럽 이름",
    "종류",
    "시도",
    "시군",
    "동면",
  ]);
  const [headerFeedName, setHeaderFeedName] = useState([
    "contents",
    "createdAt",
  ]);
  const [headerFeedNameKr, setHeaderFeedNameKr] = useState(["내용", "날짜"]);
  const [gridClubColumns, setGridClubColumns] = useState([]);
  const [gridFavoriteColumns, setGridFavoriteColumns] = useState([]);
  const [gridFeedColumns, setGridFeedColumns] = useState([]);
  const [userId] = useState(props.location.state.id);
  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);
  const [subTitle, setSubTitle] = useState("default");

  const [infoArr, setInfoArr] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [btnArr] = useState([
    "default",
    "profile",
    "signup",
    "favorite",
    "like",
    "favoritefeed",
    "writefeed",
    "writecomments",
  ]);

  const callApi = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      params.append("userId", userId);

      const userResponse = await requestGet(`/api/admin/get/user/${userId}`);
      const feedListResponse = await requestGet(
        `/api/admin/feed/liked/list?${params}`
      );
      const clubListResponse = await requestGet(
        `/api/v1/getMyClubListAll?${params}`
      );
      const bookmarkfeedResponse = await requestGet(
        `/api/admin/feed/bookmark/list?${params}`
      );
      const writefeedResponse = await requestGet(
        `/api/admin/feed/write/list?${params}`
      );
      const commentedfeedResponse = await requestGet(
        `/api/admin/feed/commented/list?${params}`
      );
      setData(userResponse);
      setFeedData(feedListResponse?.content);
      setClubData(clubListResponse);
      setBookmarkData(bookmarkfeedResponse?.content);
      setWriteData(writefeedResponse?.content);
      setCommentedData(commentedfeedResponse?.content);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      callApi();
    }
  }, [userId]);

  useEffect(() => {
    let arr = {};
    if (data) {
      arr.ProductID = data?.id;
      arr.averageMax = data?.averageMax;
      arr.averageMin = data?.averageMin;
      arr.birthDate = data?.birthDate;
      arr.birthYear = data?.birthYear;
      arr.career = data?.career;
      arr.certificated = data?.certificated;
      arr.favoriteGolfCC = data?.favoriteGolfCC;
      arr.favoriteGolfList = data?.favoriteGolfList;
      arr.favoriteScreenGolf = data?.favoriteScreenGolf;
      arr.gender = data?.gender;
      arr.golfCC = data?.golfCC;
      arr.introduction = data?.introduction;
      arr.job = data?.job;
      arr.lastLoginDate = data?.lastLoginDate;
      arr.localCity = data?.localCity;
      arr.mediaList = data?.mediaList;
      arr.membId = data?.membId;
      arr.membershipCount = data?.membershipCount;
      arr.monthlyRounding = data?.monthlyRounding;
      arr.name = data?.name;
      arr.nickname = data?.nickname;
      arr.phone = data?.phone;
      arr.point = data?.point;
      arr.proAssociation = data?.proAssociation;
      arr.proCompany = data?.proCompany;
      arr.proKind = data?.proKind;
      arr.proMemberId = data?.proMemberId;
      arr.profileImage = data?.profileImage;
      arr.public = data?.public;
      arr.registerDate = data?.registerDate;
      arr.styleTag = data?.styleTag;
      arr.userId = data?.userId;
      arr.yearlyOverseas = data?.yearlyOverseas;
      setFavoriteData(data?.favoriteGolfList);
    }
    setInfoData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (clubData?.length > 0) {
      for (let i = 0; i < clubData?.length; i++) {
        arr.push({
          description: clubData[i]?.description,
          ProductID: clubData[i]?.id,
          joined: clubData[i]?.joined,
          memberCount: clubData[i]?.memberCount,
          memberList: clubData[i]?.memberList,
          name: clubData[i]?.name,
          ownerId: clubData[i]?.ownerId,
          ownerName: clubData[i]?.ownerName,
          publicClub: clubData[i]?.publicClub,
          thumbnail: clubData[i]?.thumbnail,
        });
      }
    }
    setGridClubData(arr);
  }, [clubData]);

  useEffect(() => {
    let arr = [];
    if (feedData?.length > 0) {
      for (let i = 0; i < feedData?.length; i++) {
        arr.push({
          ProductID: feedData[i].id,
          title: feedData[i].title,
          contents: feedData[i].contents,
          createdAt: moment(feedData[i].createdAt).format("YYYY-MM-DD HH:mm"),
          data: feedData[i],
        });
      }
    }
    setGridFeedData(arr);
  }, [feedData]);

  useEffect(() => {
    let arr = [];
    if (writeData?.length > 0) {
      for (let i = 0; i < writeData?.length; i++) {
        arr.push({
          ProductID: writeData[i].id,
          title: writeData[i].title,
          contents: writeData[i].contents,
          createdAt: moment(writeData[i].createdAt).format("YYYY-MM-DD HH:mm"),
          data: writeData[i],
        });
      }
    }
    setGridWriteData(arr);
  }, [writeData]);

  useEffect(() => {
    let arr = [];
    if (bookmarkData?.length > 0) {
      for (let i = 0; i < bookmarkData?.length; i++) {
        arr.push({
          ProductID: bookmarkData[i].id,
          title: bookmarkData[i].title,
          contents: bookmarkData[i].contents,
          createdAt: moment(bookmarkData[i].createdAt).format(
            "YYYY-MM-DD HH:mm"
          ),
          data: bookmarkData[i],
        });
      }
    }
    setGridBookmarkData(arr);
  }, [bookmarkData]);

  useEffect(() => {
    let arr = [];
    if (commentedData?.length > 0) {
      for (let i = 0; i < commentedData?.length; i++) {
        arr.push({
          ProductID: commentedData[i].id,
          title: commentedData[i].title,
          contents: commentedData[i].contents,
          createdAt: moment(commentedData[i].createdAt).format(
            "YYYY-MM-DD HH:mm"
          ),
          data: commentedData[i],
        });
      }
    }
    setGridCommentedData(arr);
  }, [commentedData]);

  useEffect(() => {
    let arr = [];
    if (headerFeedName?.length > 0 && headerFeedNameKr?.length > 0) {
      for (let i = 0; i < headerFeedName?.length; i++) {
        arr.push({
          title: headerFeedNameKr[i],
          field: headerFeedName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridFeedColumns(arr);
    }
  }, [headerFeedName, headerFeedNameKr]);

  useEffect(() => {
    let arr = [];
    if (headerClubName?.length > 0 && headerClubNameKr?.length > 0) {
      for (let i = 0; i < headerClubName?.length; i++) {
        arr.push({
          title: headerClubNameKr[i],
          field: headerClubName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridClubColumns(arr);
    }
  }, [headerClubName, headerClubNameKr]);

  useEffect(() => {
    let arr = [];
    if (favoriteData?.length > 0) {
      for (let i = 0; i < favoriteData?.length; i++) {
        arr.push({
          ProductID: favoriteData[i]?.id,
          clubName: favoriteData[i]?.clubName,
          clubType: favoriteData[i]?.clubType,
          sido: favoriteData[i]?.sido,
          sigun: favoriteData[i]?.sigun,
          dongmyun: favoriteData[i]?.dongmyun,
        });
      }
    }
    setGridFavoriteData(arr);
  }, [favoriteData]);

  useEffect(() => {
    let arr = [];
    if (headerFavoriteName?.length > 0 && headerFavoriteNameKr?.length > 0) {
      for (let i = 0; i < headerFavoriteName?.length; i++) {
        arr.push({
          title: headerFavoriteNameKr[i],
          field: headerFavoriteName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridFavoriteColumns(arr);
    }
  }, [headerFavoriteName, headerFavoriteNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref]);

  const changeSubTitle = (value) => {
    setSubTitle(value);
  };

  useEffect(() => {
    if (infoData) {
      if (subTitle === "default") {
        setInfoArr([
          { name: "이름", value: infoData.name },
          { name: "전화번호", value: infoData.phone },
          { name: "닉네임", value: infoData.nickname },
          { name: "성별", value: infoData.gender },
          { name: "가입일", value: infoData.registerDate },
          { name: "최근 접속일", value: infoData.lastLoginDate },
        ]);
      } else if (subTitle === "profile") {
        setInfoArr([
          { name: "사진들", value: infoData.mediaList },
          {
            name: "자기소개",
            value: infoData.introduction,
          },
          { name: "이름", value: infoData.name },
          { name: "전화번호", value: infoData.phone },
          { name: "닉네임", value: infoData.nickname },
          { name: "생년월일", value: infoData.birthDate },
          { name: "성별", value: infoData.gender },
          {
            name: "거주지",
            value: infoData.localCity,
          },
          { name: "br" },
          {
            name: "골프정보",
          },
          {
            name: "평균 타수",
            value: `${infoData.averageMin} ~ ${infoData.averageMax}`,
          },
          { name: "골프 경력", value: infoData.career },
          { name: "보유 회원권", value: infoData.golfCC },
          { name: "br" },
          { name: "프로정보" },
          { name: "프로 유형", value: infoData.proKind },
          { name: "협회", value: infoData.proAssociation },
          { name: "회원 번호", value: infoData.proMemberId },
          { name: "소속", value: infoData.proCompany },
          { name: "br" },
          { name: "골프 스타일" },
          { name: "월평균 라운딩", value: infoData.monthlyRounding },
          { name: "해외 골프", value: infoData.yearlyOverseas },
          {
            name: "골프 태그",
            value: infoData.styleTag,
          },
          { name: "br" },
          { name: "자주가는 골프장", value: infoData.favoriteGolfCC },
          { name: "br" },
          {
            name: "자주가는 스크린 골프장",
            value: infoData.favoriteScreenGolf,
          },
          { name: "br" },
          {
            name: "프로필 공개 설정",
            value: infoData.public ? "공개" : "비공개",
          },
        ]);
      }
    }
  }, [infoData, subTitle]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    회원 상세보기
                  </Col>
                </Row>
                <div style={{ padding: "10px" }} />
                <Row>
                  {btnArr?.map((btn, i) => {
                    return (
                      <Col xs="auto" className="d-flex align-items-end" key={i}>
                        <Button
                          id={subTitle === btn ? "trueBtn" : "falseBtn"}
                          onClick={() => changeSubTitle(btn)}
                        >
                          {btn === "default"
                            ? "기준 정보"
                            : btn === "profile"
                            ? "프로필 정보"
                            : btn === "signup"
                            ? "가입 클럽"
                            : btn === "favorite"
                            ? "즐겨찾기"
                            : btn === "like"
                            ? "좋아요한 피드"
                            : btn === "favoritefeed"
                            ? "즐겨찾기 피드"
                            : btn === "writefeed"
                            ? "작성한 피드"
                            : btn === "writecomments"
                            ? "작성한 댓글"
                            : ""}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px" }} />
                    </div>
                    {subTitle !== "signup" &&
                    subTitle !== "favorite" &&
                    subTitle !== "like" &&
                    subTitle !== "favoritefeed" &&
                    subTitle !== "writecomments" &&
                    subTitle !== "writefeed" ? (
                      <div>
                        {infoArr?.map((info, i) => {
                          if (
                            info.name !== "br" &&
                            info.name !== "자주가는 골프장" &&
                            info.name !== "사진들" &&
                            info.name !== "골프정보" &&
                            info.name !== "프로정보" &&
                            info.name !== "골프 스타일" &&
                            info.name !== "자주가는 스크린 골프장"
                          ) {
                            return (
                              <div
                                style={{
                                  borderBottom: "1px solid #e4e4e4",
                                  padding: "10px 0px",
                                }}
                                key={i}
                              >
                                <h3>{info.name}</h3>
                                <p style={{ margin: 0, fontSize: "18px" }}>
                                  {info.value}
                                </p>
                              </div>
                            );
                          } else if (
                            info.name === "골프정보" ||
                            info.name === "프로정보" ||
                            info.name === "골프 스타일"
                          ) {
                            return (
                              <div
                                style={{
                                  padding: "5px 0px 0px",
                                }}
                                key={i}
                              >
                                <h2>{info.name}</h2>
                              </div>
                            );
                          } else if (info.name === "br") {
                            return (
                              <div
                                style={{
                                  borderBottom: "2px solid #000",
                                }}
                                key={i}
                              ></div>
                            );
                          } else if (
                            info.name === "자주가는 골프장" ||
                            info.name === "자주가는 스크린 골프장"
                          ) {
                            return (
                              <div
                                style={{
                                  borderBottom: "1px solid #e4e4e4",
                                  padding: "10px 0px",
                                }}
                                key={i}
                              >
                                <h3>{info.name}</h3>
                                {info.value?.map((item, j) => {
                                  if (item.clubName) {
                                    return (
                                      <span
                                        style={{ margin: 0, fontSize: "18px" }}
                                        key={j}
                                      >
                                        {j !== 0 ? "," : null}
                                        {item.clubName}
                                      </span>
                                    );
                                  }
                                })}
                              </div>
                            );
                          } else if (info.name === "사진들") {
                            return (
                              <div
                                style={{
                                  borderBottom: "1px solid #e4e4e4",
                                  padding: "10px 0px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div>
                                  <h3>{info.name}</h3>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "200px",
                                    overflow: "auto",
                                  }}
                                >
                                  {info.value?.map((item, i) => {
                                    return (
                                      <img
                                        src={item.src}
                                        alt="사진"
                                        key={i}
                                        style={{
                                          height: "100%",
                                          marginRight: "5px",
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                                {/* <Slidewithfade items={info.value} /> */}
                              </div>
                            );
                          }
                        })}
                      </div>
                    ) : subTitle === "signup" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridClubData}
                          setEditData={setGridClubData}
                          propsCloumn={gridClubColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                        />
                      </div>
                    ) : subTitle === "favorite" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridFavoriteData}
                          setEditData={setGridFavoriteData}
                          propsCloumn={gridFavoriteColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                        />
                      </div>
                    ) : subTitle === "like" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridFeedData}
                          setEditData={setGridFeedData}
                          propsCloumn={gridFeedColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                          push="/feed/detail"
                        />
                      </div>
                    ) : subTitle === "favoritefeed" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridBookmarkData}
                          setEditData={setGridBookmarkData}
                          propsCloumn={gridFeedColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                          push="/feed/detail"
                        />
                      </div>
                    ) : subTitle === "writefeed" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridWriteData}
                          setEditData={setGridWriteData}
                          propsCloumn={gridFeedColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                          push="/feed/detail"
                        />
                      </div>
                    ) : subTitle === "writecomments" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridCommentedData}
                          setEditData={setGridCommentedData}
                          propsCloumn={gridFeedColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                          push="/feed/detail"
                        />
                      </div>
                    ) : (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridClubData}
                          setEditData={setGridClubData}
                          propsCloumn={gridClubColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(UserDetail);
