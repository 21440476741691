import React, { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import "./mastermanagement.scss";
import { formPost, requestPost } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import GridTable from "../gridTable/GridTable";
import GridTableNoPage from "../gridTable/GridTableNoPage";

const BannerUpload = () => {
  const [postfiles, setPostfiles] = useState([]);
  const [postfileIds, setPostfilesIds] = useState([]);
  const [postImgfiles, setPostImgfiles] = useState([]);
  const [postImgfileIds, setPostImgfilesIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [date, setDate] = useState("");
  const [gridData, setGridData] = useState([]);
  const [gridImgData, setGridImgData] = useState([]);
  const [url, setUrl] = useState("");
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const history = useHistory();
  const [headerName] = useState(["name", "size"]);
  const [headerNameKr] = useState(["File Name", "File Size"]);
  const [headerImgName] = useState(["name", "size"]);
  const [headerImgNameKr] = useState(["File Name", "File Size"]);
  const [gridColumns, setGridColumns] = useState([]);
  const [gridImgColumns, setGridImgColumns] = useState([]);
  const [inputKey, setInputKey] = useState(Date.now());
  const [inputImgKey, setInputImgKey] = useState(
    moment().format("YYYYMMDDHHmmss")
  );
  const childComponentRef = useRef();

  const onFileHandler = (e) => {
    let arr = [];
    e.stopPropagation();
    let file = e.target.files;
    console.log(file);
    for (let i = 0; i < file.length; i++) {
      if (!postfileIds.includes(file[i].name)) {
        arr.push(file[i]);
      } else {
      }
    }
    setPostfiles(arr);
  };

  const onFileImgHandler = (e) => {
    let arr = [];
    e.stopPropagation();
    let file = e.target.files;
    for (let i = 0; i < file.length; i++) {
      if (!postImgfileIds.includes(file[i].name)) {
        arr.push(file[i]);
      } else {
      }
    }
    setPostImgfiles(arr);
  };

  const onFileApi = async (e) => {
    if (window.confirm("등록 하시겠습니까?")) {
      try {
        setLoading(true);
        const params = new URLSearchParams();
        params.append("title", title);
        params.append("contents", contents);
        params.append("outsideUrl", url);
        if (date && date !== "Invalid date") {
          params.append("targetDate", date);
        }
        let data = params;
        let res = await requestPost("/api/admin/banner/write", data);
        if (res.contentId && res.userId) {
          const param = new URLSearchParams();
          param.append("userId", res.userId);
          param.append("boardId", res.contentId);
          if (postfiles.length > 0) {
            var bodyFormData = new FormData();
            bodyFormData.append("file", postfiles[0]);
            await formPost(
              `/uploadFile/BANNER_THUMBNAIL/IMAGE?${param}`,
              bodyFormData
            );
          }
          if (postImgfiles.length > 0) {
            var bodyImgFormData = new FormData();
            bodyImgFormData.append("file", postImgfiles[0]);
            await formPost(
              `/uploadFile/BANNER/IMAGE?${param}`,
              bodyImgFormData
            );
          }
        }
        setLoading(false);
        alert("등록하였습니다.");
        history.goBack();
      } catch (error) {
        setLoading(false);
        alert("등록 실패 하였습니다.");
      }
    }
  };

  useEffect(() => {
    let ids = [];
    let arr = [];
    if (postfiles.length > 0) {
      for (const el of postfiles) {
        ids.push(el.name);
        arr.push({
          name: el.name,
          size: el.size,
        });
      }
      setPostfilesIds(ids);
      setGridData(arr);
    }
  }, [postfiles]);

  useEffect(() => {
    let ids = [];
    let arr = [];
    if (postImgfiles.length > 0) {
      for (const el of postImgfiles) {
        ids.push(el.name);
        arr.push({
          name: el.name,
          size: el.size,
        });
      }
      setPostImgfilesIds(ids);
      setGridImgData(arr);
    }
  }, [postImgfiles]);
  console.log(inputImgKey);

  const removelistImg = (name) => {
    setPostfiles([]);
    setPostfilesIds([]);
    setInputKey(Date.now());
  };

  const removeImg = (name) => {
    setPostImgfiles([]);
    setPostImgfilesIds([]);
    setInputImgKey(moment().format("YYYYMMDDHHmmss"));
  };

  const onTitle = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const onContents = (e) => {
    e.preventDefault();
    setContents(e.target.value);
  };

  const handleDate = (e) => {
    setDate(moment(e.target.value).format("YYYYMMDD"));
  };

  const onURL = (e) => {
    e.preventDefault();
    setUrl(e.target.value);
  };
  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  useEffect(() => {
    let arr = [];
    if (headerImgName?.length > 0 && headerImgNameKr?.length > 0) {
      for (let i = 0; i < headerImgName?.length; i++) {
        arr.push({
          title: headerImgNameKr[i],
          field: headerImgName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridImgColumns(arr);
    }
  }, [headerImgName, headerImgNameKr]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container fluid>
        <Row className="w-100 m-0 d-flex justify-content-center">
          <Col className="col-12 px-5">
            <Row className="m-0">
              <Row className="w-100 mx-0 my-0 p-0">
                <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                  배너 등록
                </Col>
              </Row>
              <div style={{ padding: "10px" }} />
            </Row>
            <Row className="w-100 m-0 mb-4">
              <Col>
                <Row>
                  <Col className="p-0">
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          제목
                          <Input type="text" value={title} onChange={onTitle} />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          내용
                          <Input
                            type="textarea"
                            rows="3"
                            style={{ resize: "none" }}
                            value={contents}
                            onChange={onContents}
                          />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          외부 URL
                          <Input type="text" value={url} onChange={onURL} />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          날짜
                          <input
                            className="form-control"
                            type="date"
                            value={moment(date).format("YYYY-MM-DD")}
                            onChange={handleDate}
                            min={moment().format("YYYY-MM-DD")}
                            max={"9999-12-31"}
                          />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ display: "flex", flexDirection: "column" }}>
                        <Label
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          리스트 표시용 배너 이미지 파일 선택
                        </Label>
                        <div>
                          <Label className="fileAddName">
                            <Input
                              type="file"
                              id="image"
                              key={inputKey}
                              onChange={onFileHandler}
                            />
                            <span
                              className="btn acceptBtn"
                              style={{
                                padding: "0.47rem 0.75rem",
                                borderRadius: "5px",
                                color: "#fff",
                              }}
                            >
                              Select files
                            </span>
                          </Label>
                          <Button
                            className="dangerBtn ms-2"
                            onClick={() => removelistImg()}
                          >
                            remove
                          </Button>
                        </div>
                      </Col>
                      {postfiles.length > 0 ? (
                        <div className="mx-0 mt-3">
                          <div style={{ display: "flex" }}>
                            <div>
                              <GridTableNoPage
                                editData={gridData}
                                setEditData={setGridData}
                                propsCloumn={gridColumns}
                                setEditId={setEditId}
                                setIds={setIds}
                                ids={ids}
                                ref={childComponentRef}
                                shop
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Row>
                    <Row>
                      <Col style={{ display: "flex", flexDirection: "column" }}>
                        <Label
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          배너 전체 이미지 파일 선택
                        </Label>
                        <div>
                          <Label className="fileAddName">
                            <Input
                              type="file"
                              id="image"
                              key={inputImgKey}
                              onChange={onFileImgHandler}
                            />
                            <span
                              className="btn acceptBtn"
                              style={{
                                padding: "0.47rem 0.75rem",
                                borderRadius: "5px",
                                color: "#fff",
                              }}
                            >
                              Select files
                            </span>
                          </Label>
                          <Button
                            className="dangerBtn ms-2"
                            onClick={() => removeImg()}
                          >
                            remove
                          </Button>
                        </div>
                      </Col>
                      {postImgfiles.length > 0 ? (
                        <div className="mx-0 mt-3">
                          <div style={{ display: "flex" }}>
                            <div>
                              <GridTableNoPage
                                editData={gridImgData}
                                setEditData={setGridImgData}
                                propsCloumn={gridImgColumns}
                                setEditId={setEditId}
                                setIds={setIds}
                                ids={ids}
                                ref={childComponentRef}
                                shop
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        width: "auto",
                        marginBottom: "10px",
                      }}
                    >
                      <Col xs="auto" className="d-flex align-items-end">
                        <Button
                          className="acceptBtn"
                          onClick={() => onFileApi()}
                        >
                          등록하기
                        </Button>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(BannerUpload);
