import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import SweetAlert from "react-bootstrap-sweetalert";
import GoogleCustomMap from "../map/GoogleCustomMap";
import { requestGet } from "../../apis/request";
import { InputWrap } from "../Common/InputWrap";

const MapPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [location, setLocation] = useState([]);
  const [age, setAge] = useState([]);
  const [inputKey, setInputKey] = useState([
    {
      name: "이름",
      key: "userName",
      type: "text",
    },
    {
      name: "전화번호",
      key: "phone",
      type: "text",
    },
    {
      name: "성별",
      key: "gender",
      type: "dropdown",
      data: [
        { key: "", value: "전체" },
        { key: "MALE", value: "남자" },
        { key: "FEMALE", value: "여자" },
      ],
    },
    {
      name: "지역",
      key: "location",
      type: "dropdown",
      defaultValue: "",
      data: location,
    },
    {
      name: "연령",
      key: "ageKey",
      type: "dropdown",
      defaultValue: "0",
      data: age,
    },
    {
      name: "아이디From",
      key: "idFrom",
      type: "text",
    },
    {
      name: "아이디To",
      key: "idTo",
      type: "text",
    },
  ]);
  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);

  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  const searchApi = async () => {
    setLoading(true);
    try {
      let obj = inputKey;
      let locationArr = [{ key: "", value: "전체" }];
      let ageArr = [];
      const locationResponse = await requestGet("/api/admin/location/list");
      const ageResponse = await requestGet("/api/admin/age/list");
      if (locationResponse?.length > 0) {
        for (let i = 0; i < locationResponse.length; i++) {
          locationArr.push({
            key: locationResponse[i],
            value: locationResponse[i],
          });
        }
        setLocation(locationArr);
      } else {
        setLocation(null);
      }
      if (ageResponse?.length > 0) {
        for (let i = 0; i < ageResponse.length; i++) {
          ageArr.push({
            key: ageResponse[i].value,
            value: ageResponse[i].key,
          });
        }
        setAge(ageArr);
      } else {
        setAge(null);
      }
      obj?.map((item, i) => {
        if (item.key === "location") {
          item.data = locationArr;
        }
        if (item.key === "ageKey") {
          item.data = ageArr;
        }
        return item;
      });
      setInputKey(obj);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    searchApi();
  }, []);

  const callApi = async () => {
    setLoading(true);
    try {
      const response = await requestGet("/api/admin/user/location/list");
      setData(response);
      setLoading(false);
    } catch (error) {
      setData([]);
      // alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (topBarref.current && searchref.current) {
      setMaxH(
        topBarref.current?.offsetHeight - searchref.current?.offsetHeight
      );
    }
  }, [topBarref, searchref]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", padding: 0 }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <div className="m-0" ref={searchref}>
            <div id="headerTilteID">사용자 위치</div>
            <InputWrap
              keys={keys}
              inputKey={inputKey}
              inputObj={inputObj}
              setInputObj={setInputObj}
              callApi={callApi}
            />
          </div>
          <div
            id="tableWrapWidth"
            style={{
              display: "flex",
              alignItems: "center",
              height: `${maxH}px`,
            }}
          >
            <GoogleCustomMap markers={data} />
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(MapPage);
