import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import GridTable from "../gridTable/GridTable";
import Slidewithfade from "../Common/Slidewithfade";
import moment from "moment";

const FeedDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [propsData] = useState(props.location.state.data);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerName] = useState(["comment", "createDate"]);
  const [headerNameKr] = useState(["내용", "날짜"]);
  const [gridColumns, setGridColumns] = useState([]);

  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const btnref1 = useRef(null);
  const [maxH, setMaxH] = useState(0);

  const [infoArr, setInfoArr] = useState([]);
  const [infoData, setInfoData] = useState(null);

  const callApi = async (id) => {
    setLoading(true);
    try {
      const res = await requestGet(`/api/v1/getFeedCommnetsByFeedId/${id}`);
      setData(res);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    let arr = {};
    console.log(propsData);
    if (propsData) {
      arr.allowComment = propsData.allowComment;
      arr.areaCode = propsData.areaCode;
      arr.bookmarkCount = propsData.bookmarkCount;
      arr.bookmarkList = propsData.bookmarkList;
      arr.bookmarked = propsData.bookmarked;
      arr.clubCode = propsData.clubCode;
      arr.commentCount = propsData.commentCount;
      arr.contents = propsData.contents;
      arr.createdAt = propsData.createdAt;
      arr.feedKind = propsData.feedKind;
      arr.id = propsData.id;
      arr.latestComment = propsData.latestComment;
      arr.likes = propsData.likes;
      arr.media = propsData.media;
      arr.pclubName = propsData.pclubName;
      arr.privacySetting = propsData.privacySetting;
      arr.starRating = propsData.starRating;
      arr.tags = propsData.tags;
      arr.title = propsData.title;
      arr.userId = propsData.userId;
      arr.userProfile = propsData.userProfile;
      callApi(propsData.id);
    }
    setInfoData(arr);
  }, [propsData]);

  useEffect(() => {
    if (infoData) {
      setInfoArr([
        { name: "title", value: infoData.title },
        {
          name: "contents",
          value: infoData.contents,
        },
        {
          name: "createdAt",
          value: moment(infoData.createdAt).format("YYYY-MM-DD HH:mm"),
        },
        { name: "br" },
        {
          name: "유저 정보",
        },
        { name: "닉네임", value: infoData.userProfile?.nickname },
        { name: "생년월일", value: infoData.userProfile?.birthDate },
        { name: "성별", value: infoData.userProfile?.gender },
        {
          name: "거주지",
          value: infoData.userProfile?.localCity,
        },
        {
          name: "평균 타수",
          value: `${infoData.userProfile?.averageMin} ~ ${infoData.userProfile?.averageMax}`,
        },
        { name: "골프 경력", value: infoData.userProfile?.career },
        { name: "보유 회원권", value: infoData.userProfile?.golfCC },
      ]);
    }
  }, [infoData]);

  useEffect(() => {
    let arr = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        arr.push({
          ProductID: data[i].id,
          blocked: data[i].blocked,
          comment: data[i].comment,
          commentDepth: data[i].commentDepth,
          createDate: moment(data[i].createDate).format("YYYY-MM-DD"),
          deleted: data[i].deleted,
          feedId: data[i].feedId,
          groupCommentId: data[i].groupCommentId,
          groupCommentUserId: data[i].groupCommentUserId,
          id: data[i].id,
          targetCommentId: data[i].targetCommentId,
          targetCommentUserId: data[i].targetCommentUserId,
          updateDate: data[i].updateDate,
          userId: data[i].userId,
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  useEffect(() => {
    if (
      topBarref.current &&
      searchref.current &&
      btnref.current &&
      btnref1.current
    ) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          btnref1.current?.offsetHeight -
          5
      );
    }
  }, [topBarref, searchref, btnref, btnref1]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{
          height: "49%",
          maxHeight: "49%",
          paddingTop: "15px",
          overflow: "auto",
        }}
      >
        <div className="w-100 h-100 m-0">
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0">
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    피드 상세보기
                  </Col>
                </Row>
                <div style={{ padding: "10px" }} />
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div>
                      <div style={{ padding: "10px" }} />
                    </div>
                    <div>
                      {infoArr?.map((info, i) => {
                        if (
                          info.name !== "br" &&
                          info.name !== "자주가는 골프장" &&
                          info.name !== "사진들" &&
                          info.name !== "유저 정보" &&
                          info.name !== "프로정보" &&
                          info.name !== "골프 스타일" &&
                          info.name !== "자주가는 스크린 골프장"
                        ) {
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #e4e4e4",
                                padding: "10px 0px",
                              }}
                              key={i}
                            >
                              <h3>{info.name}</h3>
                              <p style={{ margin: 0, fontSize: "18px" }}>
                                {info.value}
                              </p>
                            </div>
                          );
                        } else if (
                          info.name === "유저 정보" ||
                          info.name === "프로정보" ||
                          info.name === "골프 스타일"
                        ) {
                          return (
                            <div
                              style={{
                                padding: "5px 0px 0px",
                              }}
                              key={i}
                            >
                              <h2>{info.name}</h2>
                            </div>
                          );
                        } else if (info.name === "br") {
                          return (
                            <div
                              style={{
                                borderBottom: "2px solid #000",
                              }}
                              key={i}
                            ></div>
                          );
                        } else if (
                          info.name === "자주가는 골프장" ||
                          info.name === "자주가는 스크린 골프장"
                        ) {
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #e4e4e4",
                                padding: "10px 0px",
                              }}
                              key={i}
                            >
                              <h3>{info.name}</h3>
                              {info.value?.map((item, j) => {
                                return (
                                  <span
                                    style={{ margin: 0, fontSize: "18px" }}
                                    key={j}
                                  >
                                    {j !== 0 ? "," : null}
                                    {item}
                                  </span>
                                );
                              })}
                            </div>
                          );
                        } else if (info.name === "사진들") {
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #e4e4e4",
                                padding: "10px 0px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <h3>{info.name}</h3>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "200px",
                                  overflow: "auto",
                                }}
                              >
                                {info.value?.map((item, i) => {
                                  return (
                                    <img
                                      src={item.src}
                                      alt="사진"
                                      key={i}
                                      style={{
                                        height: "100%",
                                        marginRight: "5px",
                                      }}
                                    />
                                  );
                                })}
                              </div>
                              {/* <Slidewithfade items={info.value} /> */}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <div style={{ border: "1px solid" }}></div>
      <Container
        fluid
        style={{
          height: "50%",
          maxHeight: "50%",
          paddingTop: "15px",
          overflow: "auto",
        }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0">댓글 목록</Col>
                </Row>
                <div style={{ padding: "10px" }} />
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px" }} />
                    </div>
                    <div>
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridData}
                          setEditData={setGridData}
                          propsCloumn={gridColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          push="/club/detail"
                          ref={childComponentRef}
                        />
                      </div>
                    </div>
                    <div ref={btnref1}>
                      <div
                        style={{
                          display: "flex",
                          width: "auto",
                          padding: "10px 0px",
                        }}
                      >
                        <Col xs="auto" className="d-flex align-items-end">
                          <Button
                            id="trueBtn"
                            // onClick={() => changeSubTitle(btn)}
                          >
                            삭제하기
                          </Button>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(FeedDetail);
