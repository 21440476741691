import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { adminRequestPost, requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import GridTable from "../gridTable/GridTable";
import Slidewithfade from "../Common/Slidewithfade";

const ProfileDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);

  const [infoArr, setInfoArr] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const history = useHistory();
  useEffect(() => {
    setData(props.location.state.data);
  }, [props]);
  // const callApi = async () => {
  //   setLoading(true);
  //   try {
  //     const params = new URLSearchParams();
  //     params.append("userId", userId);

  //     const userResponse = await requestGet(`/api/admin/get/user/${userId}`);
  //     const clubListResponse = await requestGet(
  //       `/api/v1/getMyClubListAll?${params}`
  //     );
  //     const feedResponse = await requestGet(
  //       `/api/v1/get/bookmark/feed/${userId}`
  //     );
  //     setData(userResponse);
  //     setClubData(clubListResponse);
  //     setFeedData(feedResponse);
  //     setLoading(false);
  //   } catch (error) {
  //     setData([]);
  //     alert("잠시후 다시 실행해 주세요.");
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    let arr = {};
    if (data) {
      arr.ProductID = data?.id;
      arr.averageMax = data?.averageMax;
      arr.averageMin = data?.averageMin;
      arr.birthDate = data?.birthDate;
      arr.birthYear = data?.birthYear;
      arr.career = data?.career;
      arr.certificated = data?.certificated;
      arr.favoriteGolfCC = data?.favoriteGolfCC;
      arr.favoriteGolfList = data?.favoriteGolfList;
      arr.favoriteScreenGolf = data?.favoriteScreenGolf;
      arr.gender = data?.gender;
      arr.golfCC = data?.golfCC;
      arr.introduction = data?.introduction;
      arr.job = data?.job;
      arr.lastLoginDate = data?.lastLoginDate;
      arr.localCity = data?.localCity;
      arr.mediaList = data?.mediaList;
      arr.membId = data?.membId;
      arr.membershipCount = data?.membershipCount;
      arr.monthlyRounding = data?.monthlyRounding;
      arr.name = data?.name;
      arr.nickname = data?.nickname;
      arr.phone = data?.phone;
      arr.point = data?.point;
      arr.proAssociation = data?.proAssociation;
      arr.proCompany = data?.proCompany;
      arr.proKind = data?.proKind;
      arr.proMemberId = data?.proMemberId;
      arr.profileImage = data?.profileImage;
      arr.public = data?.public;
      arr.registerDate = data?.registerDate;
      arr.styleTag = data?.styleTag;
      arr.userId = data?.userId;
      arr.yearlyOverseas = data?.yearlyOverseas;
    }
    setInfoData(arr);
  }, [data]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref]);

  useEffect(() => {
    if (infoData) {
      setInfoArr([
        { name: "사진들", value: infoData?.mediaList },
        {
          name: "자기소개",
          value: infoData?.introduction,
        },
        { name: "이름", value: infoData?.name },
        { name: "전화번호", value: infoData?.phone },
        { name: "닉네임", value: infoData?.nickname },
        { name: "생년월일", value: infoData?.birthDate },
        { name: "성별", value: infoData?.gender },
        {
          name: "거주지",
          value: infoData?.localCity,
        },
        { name: "br" },
        {
          name: "골프정보",
        },
        {
          name: "평균 타수",
          value: `${infoData?.averageMin} ~ ${infoData?.averageMax}`,
        },
        { name: "골프 경력", value: infoData?.career },
        { name: "보유 회원권", value: infoData?.golfCC },
        { name: "br" },
        { name: "프로정보" },
        { name: "프로 유형", value: infoData?.proKind },
        { name: "협회", value: infoData?.proAssociation },
        { name: "회원 번호", value: infoData?.proMemberId },
        { name: "소속", value: infoData?.proCompany },
        { name: "br" },
        { name: "골프 스타일" },
        { name: "월평균 라운딩", value: infoData?.monthlyRounding },
        { name: "해외 골프", value: infoData?.yearlyOverseas },
        {
          name: "골프 태그",
          value: infoData?.styleTag,
        },
        { name: "br" },
        { name: "자주가는 골프장", value: infoData?.favoriteGolfCC },
        { name: "br" },
        {
          name: "자주가는 스크린 골프장",
          value: infoData?.favoriteScreenGolf,
        },
        { name: "br" },
        {
          name: "프로필 공개 설정",
          value: infoData?.public ? "공개" : "비공개",
        },
        { name: "br" },
      ]);
    }
  }, [infoData]);

  const onPrompt = () => {
    let reason = window.prompt("사유를 적어주세요.");
    return reason;
  };

  const onAccept = async () => {
    if (window.confirm("승인 하시겠습니까?")) {
      let reason = "";
      reason = await onPrompt();
      try {
        const params = new URLSearchParams();
        params.append("id", infoData.ProductID);
        params.append("userId", infoData.userId);
        params.append("message", reason);
        params.append("confrim", 3);
        let body = {
          id: infoData.ProductID,
          userId: infoData.userId,
          message: reason,
          confrim: 3,
        };
        await adminRequestPost(`/api/admin/profile/confirm`, body);
        alert("승인하였습니다.");
        history.goBack();
        setLoading(false);
      } catch (error) {
        setData([]);
        alert("잠시후 다시 실행해 주세요.");
        setLoading(false);
      }
    }
  };

  const onReject = async () => {
    if (window.confirm("반려 하시겠습니까?")) {
      let reason = "";
      reason = await onPrompt();
      try {
        let body = {
          id: infoData.ProductID,
          userId: infoData.userId,
          message: reason,
          confrim: 2,
        };
        await adminRequestPost(`/api/admin/profile/confirm`, body);
        alert("반려하였습니다.");
        history.goBack();
        setLoading(false);
      } catch (error) {
        setData([]);
        alert("잠시후 다시 실행해 주세요.");
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    회원 상세보기
                  </Col>
                </Row>
                <div style={{ padding: "10px" }} />
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px" }} />
                    </div>
                    <div>
                      {infoArr?.map((info, i) => {
                        if (
                          info.name !== "br" &&
                          info.name !== "자주가는 골프장" &&
                          info.name !== "사진들" &&
                          info.name !== "골프정보" &&
                          info.name !== "프로정보" &&
                          info.name !== "골프 스타일" &&
                          info.name !== "자주가는 스크린 골프장"
                        ) {
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #e4e4e4",
                                padding: "10px 0px",
                              }}
                              key={i}
                            >
                              <h3>{info.name}</h3>
                              <p style={{ margin: 0, fontSize: "18px" }}>
                                {info.value}
                              </p>
                            </div>
                          );
                        } else if (
                          info.name === "골프정보" ||
                          info.name === "프로정보" ||
                          info.name === "골프 스타일"
                        ) {
                          return (
                            <div
                              style={{
                                padding: "5px 0px 0px",
                              }}
                              key={i}
                            >
                              <h2>{info.name}</h2>
                            </div>
                          );
                        } else if (info.name === "br") {
                          return (
                            <div
                              style={{
                                borderBottom: "2px solid #000",
                              }}
                              key={i}
                            ></div>
                          );
                        } else if (
                          info.name === "자주가는 골프장" ||
                          info.name === "자주가는 스크린 골프장"
                        ) {
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #e4e4e4",
                                padding: "10px 0px",
                              }}
                              key={i}
                            >
                              <h3>{info.name}</h3>
                              {info.value?.map((item, j) => {
                                if (item.clubName) {
                                  return (
                                    <span
                                      style={{ margin: 0, fontSize: "18px" }}
                                      key={j}
                                    >
                                      {j !== 0 ? "," : null}
                                      {item.clubName}
                                    </span>
                                  );
                                }
                              })}
                            </div>
                          );
                        } else if (info.name === "사진들") {
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #e4e4e4",
                                padding: "10px 0px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <h3>{info.name}</h3>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "200px",
                                  overflow: "auto",
                                }}
                              >
                                {info.value?.map((item, i) => {
                                  return (
                                    <img
                                      src={item.src}
                                      alt="사진"
                                      key={i}
                                      style={{
                                        height: "100%",
                                        marginRight: "5px",
                                      }}
                                    />
                                  );
                                })}
                              </div>
                              {/* <Slidewithfade items={info.value} /> */}
                            </div>
                          );
                        }
                      })}
                      <div style={{ margin: "10px 0px", textAlign: "end" }}>
                        <Button
                          className="acceptBtn"
                          onClick={() => onAccept()}
                        >
                          승인
                        </Button>
                        <Button
                          className="dangerBtn ms-2"
                          onClick={() => onReject()}
                        >
                          반려
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(ProfileDetail);
