import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestPost } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router";

const BannerDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [propsData] = useState(props.location.state.data);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);

  const [infoArr, setInfoArr] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    let arr = {};
    if (propsData) {
      arr.contentId = propsData?.contentId;
      arr.contents = propsData?.contents;
      arr.createAt = propsData?.createAt;
      arr.fileUrl = propsData?.fileUrl;
      arr.files = propsData?.files;
      arr.outsideUrl = propsData?.outsideUrl;
      arr.targetDate = propsData?.targetDate;
      arr.targetEndDate = propsData?.targetEndDate;
      arr.thumbnailUrl = propsData?.thumbnailUrl;
      arr.thumbnails = propsData?.thumbnails;
      arr.title = propsData?.title;
      arr.updateAt = propsData?.updateAt;
      arr.userId = propsData?.userId;
    }
    setInfoData(arr);
  }, [propsData]);

  useEffect(() => {
    if (infoData) {
      setInfoArr([
        {
          name: "배너 정보",
        },
        { name: "제목", value: infoData?.title },
        {
          name: "내용",
          value: infoData?.contents,
        },
        {
          name: "외부 url",
          value: infoData?.outsideUrl,
        },
        {
          name: "날짜",
          value: infoData?.targetDate,
        },
        { name: "배너 썸네일 이미지", value: infoData?.thumbnailUrl },
        { name: "배너 이미지", value: infoData?.fileUrl },
      ]);
    }
  }, [infoData]);

  const onDelete = async () => {
    if (window.confirm("정말 삭제 하시겠습니까?")) {
      try {
        await requestPost(`/api/admin/banner/delete/${propsData?.contentId}`);
        alert("삭제 성공 하였습니다.");
        history.goBack();
      } catch (error) {
        console.log(error);
        alert("삭제 실패 하였습니다.");
      }
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    배너 상세보기
                  </Col>
                </Row>
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref} className="text-end">
                      <Button id="trueBtn" onClick={() => onDelete()}>
                        삭제하기
                      </Button>
                    </div>
                    <div>
                      {infoArr?.map((info, i) => {
                        if (
                          info.name !== "br" &&
                          info.name !== "자주가는 골프장" &&
                          info.name !== "배너 썸네일 이미지" &&
                          info.name !== "배너 이미지" &&
                          info.name !== "배너 정보" &&
                          info.name !== "프로정보" &&
                          info.name !== "골프 스타일" &&
                          info.name !== "자주가는 스크린 골프장"
                        ) {
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #e4e4e4",
                                padding: "10px 0px",
                              }}
                              key={i}
                            >
                              <h3>{info.name}</h3>
                              <p style={{ margin: 0, fontSize: "18px" }}>
                                {info.value}
                              </p>
                            </div>
                          );
                        } else if (
                          info.name === "배너 정보" ||
                          info.name === "프로정보" ||
                          info.name === "골프 스타일"
                        ) {
                          return (
                            <div
                              style={{
                                padding: "5px 0px 0px",
                              }}
                              key={i}
                            >
                              <h2>{info.name}</h2>
                            </div>
                          );
                        } else if (info.name === "br") {
                          return (
                            <div
                              style={{
                                borderBottom: "2px solid #000",
                              }}
                              key={i}
                            ></div>
                          );
                        } else if (
                          info.name === "자주가는 골프장" ||
                          info.name === "자주가는 스크린 골프장"
                        ) {
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #e4e4e4",
                                padding: "10px 0px",
                              }}
                              key={i}
                            >
                              <h3>{info.name}</h3>
                              {info.value?.map((item, j) => {
                                return (
                                  <span
                                    style={{ margin: 0, fontSize: "18px" }}
                                    key={j}
                                  >
                                    {j !== 0 ? "," : null}
                                    {item}
                                  </span>
                                );
                              })}
                            </div>
                          );
                        } else if (
                          info.name === "배너 썸네일 이미지" ||
                          info.name === "배너 이미지"
                        ) {
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #e4e4e4",
                                padding: "10px 0px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <h3>{info.name}</h3>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "200px",
                                  overflow: "auto",
                                }}
                              >
                                {info.value ? (
                                  <img
                                    src={info.value}
                                    alt={info.name}
                                    style={{
                                      height: "100%",
                                      marginRight: "5px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <Slidewithfade items={info.value} /> */}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(BannerDetail);
