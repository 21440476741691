import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
} from "react-table";

const Styles = styled.div`
  ${
    "" /* These styles are suggested for the table fill all available space in its containing element */
  }
  display: block;
  ${
    "" /* These styles are required for a horizontaly scrollable table overflow */
  }
  width: 100%;
  height: 100%;
  .table {
    height: 100%;
    overflow: hidden;
    margin: 0;
    box-shadow: inset 0 0 0 1px lightgray;
    .tableheaderwrap {
      background-color: transparent;
      position: sticky;
      z-index: 1;
      top: 0;
      padding: 0;
    }
    .thead {
      ${
        "" /* These styles are required for a scrollable body to align with the header properly */
      }
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: inset 0 0 0 1px lightgray;
    }

    .tbodywrap {
      // border-spacing: 0;
      // border: 1px solid black;
      background: "#f8f8f8";
    }
    // .tbodyWrap {
    //   &::-webkit-scrollbar {
    //     width: 15px;
    //     height: 15px;
    //     border-radius: 6px;
    //     background: rgba(255, 255, 255, 0.4);
    //   }
    //   &::-webkit-scrollbar-thumb {
    //     background: rgba(0, 0, 0, 0.3);
    //     border-radius: 6px;
    //   }
    // }

    .tbody {
      ${"" /* These styles are required for a scrollable table body */}
      // overflow-x: hidden;
      height: 250px;
      width: auto;
      background-color: transparent;
    }

    .tr {
      height: auto;
      padding: 0;
      border-width: 0;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      box-shadow: inset 0 0 0 1px lightgray;
      ${
        "" /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
      }
      .tablespan {
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
      .resizer {
        right: 0;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;
      }
    }
  }
`;

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const Tablecopy = ({ columns, data }) => {
  const tableRef = useRef(null);
  const tableTopRef = useRef(null);
  const tableTopWidthRef = useRef(null);
  const [maxH, setMaxH] = useState(0);
  const [maxW, setMaxW] = useState(0);
  const [scrollbarW, setScrollbarW] = useState(0);
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);

  const handleScroll = (source, target) => {
    const { scrollLeft } = source.current;
    target.current.style.transform = `translateX(-${scrollLeft}px)`;
  };

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      width: 150, // width is used for both the flex-basis and flex-grow
    }),
    []
  );

  useEffect(() => {
    if (tableRef.current && tableTopRef.current) {
      setMaxH(
        tableRef.current?.offsetHeight - tableTopRef.current?.offsetHeight
      );
    }
  }, [tableRef, tableTopRef, data]);

  const { getTableProps, headerGroups, rows, prepareRow, state } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          disableResizing: true,
          minWidth: 35,
          width: 35,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  );
  useEffect(() => {
    setMaxW(tableTopWidthRef.current?.offsetWidth);
  }, [state]);

  useEffect(() => {
    setScrollbarW(div2Ref.current.offsetWidth - div1Ref.current.offsetWidth);
  }, [div1Ref, div2Ref]);

  return (
    <Styles>
      <div {...getTableProps()} className="table" ref={tableRef}>
        <div
          style={{
            paddingRight: scrollbarW,
            boxShadow: "inset 0 0 0 1px lightgray",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              boxShadow: "inset 0 0 0 1px lightgray",
              background: "#f8f8f8",
            }}
          >
            <div
              ref={tableTopRef}
              className="tableheaderwrap"
              onScroll={() => handleScroll(tableTopRef, div2Ref)}
            >
              {headerGroups.map((headerGroup) => (
                <div
                  {...headerGroup.getHeaderGroupProps({
                    // style: { paddingRight: '15px' },
                  })}
                  className="tr"
                  ref={tableTopWidthRef}
                >
                  {headerGroup.headers.map((column) => (
                    <div {...column.getHeaderProps(headerProps)} className="th">
                      <span className="tablespan">
                        {column.render("Header")}
                      </span>
                      {/* Use column.getResizerProps to hook up the events correctly */}
                      {column.canResize && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? "isResizing" : ""
                          }`}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          ref={div2Ref}
          className="tbodyWrap"
          onScroll={() => handleScroll(div2Ref, tableTopRef)}
          style={{
            height: maxH,
            minWidth: "100%",
            overflow: "scroll",
            boxShadow: "inset 0 0 0 1px lightgray",
          }}
        >
          <div
            ref={div1Ref}
            className="intbody"
            style={{ height: maxH, width: "100%" }}
          >
            <div
              className="tbody"
              style={{ height: maxH, width: maxW, padding: 0 }}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => {
                      return (
                        <div {...cell.getCellProps(cellProps)} className="td">
                          <span className="tablespan">
                            {cell.render("Cell")}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default Tablecopy;
