import moment from "moment";
import * as React from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import SearchBarWrapCheaps from "./SearchBarWrapCheaps";
export const InputWrap = ({
  keys,
  inputKey,
  inputObj,
  setInputObj,
  callApi,
  btntitle,
}) => {
  const [inputText, setInputText] = React.useState({});
  const [flag, setFlag] = React.useState(false);

  React.useEffect(() => {
    let obj = {};
    keys?.map((el) => {
      obj[el] = "";
    });
    inputKey?.map((item) => {
      if (item.type === "text") {
        if (item.defaultValue) {
          obj[item.key] = item.defaultValue;
        }
      }
      if (item.type === "dropdown") {
        if (item.defaultValue) {
          obj[item.key] = item.defaultValue;
        }
      }
      if (item.type === "date") {
        if (item.defaultDate) {
          obj[item.key] = item.defaultDate;
        }
      } else if (item.type === "month") {
        if (item.defaultDate) {
          obj[item.key] = moment(item.defaultDate).format("YYYY.MM");
        }
      }
    });
    setInputText(obj);
    setInputObj(obj);
  }, [inputKey]);

  const changeInputValue = (e, key) => {
    let arr = inputObj;
    arr[key] = e.target.value;
    setInputText(arr);
    setInputObj(arr);
  };

  const changeInputMonthValue = (e, key) => {
    let arr = inputObj;
    arr[key] = moment(e.target.value).format("YYYY.MM");
    setInputText(arr);
    setInputObj(arr);
  };

  const changeCheckValue = (e, key) => {
    let arr = inputObj;
    arr[key] = e.target.checked;
    setInputText(arr);
    setInputObj(arr);
  };

  const onDatePaste = (e, key) => {
    var text = e.clipboardData.getData("Text");
    let arr = inputObj;
    arr[key] = moment(text).format("YYYY-MM-DD");
    setInputText(arr);
    setInputObj(arr);
    setFlag(!flag);
  };

  return (
    <Row className="w-100 mx-0 p-0 d-flex align-items-center">
      <div className="w-100 p-0">
        <Col className="masterHeader">
          <Row>
            <Col>
              <Row xs="1" sm="2" md="3" lg="5" xl="6">
                {inputKey?.map((el, i) => {
                  if (el.type === "text") {
                    if (el.auto) {
                      return (
                        <Col style={{ marginBottom: "10px" }} key={i}>
                          <FormGroup>
                            <Label
                              style={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              {el.name}
                            </Label>
                            <SearchBarWrapCheaps
                              setInputValue={setInputText}
                              inputValue={inputText}
                              type={el.key}
                            />
                          </FormGroup>
                        </Col>
                      );
                    } else {
                      return (
                        <Col style={{ marginBottom: "10px" }} key={i}>
                          <FormGroup>
                            <Label
                              style={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              {el.name}
                            </Label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue={inputObj[el.key]}
                              onChange={(e) => changeInputValue(e, el.key)}
                              // onKeyPress={onKeyPress}
                            />
                          </FormGroup>
                        </Col>
                      );
                    }
                  } else if (el.type === "number") {
                    return (
                      <Col style={{ marginBottom: "10px" }} key={i}>
                        <FormGroup>
                          <Label
                            style={{
                              color: "#000",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {el.name}
                          </Label>
                          <input
                            className="form-control"
                            type="number"
                            defaultValue={inputObj[el.key]}
                            onChange={(e) => changeInputValue(e, el.key)}
                            onWheel={(e) => e.target.blur()}
                            // onKeyPress={onKeyPress}
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else if (el.type === "modal") {
                    return (
                      <Col style={{ marginBottom: "10px" }}>
                        <FormGroup>
                          <Label
                            style={{
                              color: "#000",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {el.name}
                          </Label>
                          <div
                            className="form-control"
                            style={{
                              textAlign: "left",
                              overflow: "auto",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              cursor: "pointer",
                              whiteSpace: "pre",
                            }}
                            onClick={() => el.api()}
                          >
                            <span>
                              {el.dataList?.length === 0
                                ? "전체"
                                : el.dataList?.length === 1
                                ? el.dataName
                                : el.dataList?.length > 1
                                ? `${el.dataName}...`
                                : null}
                            </span>
                            <i className="fas fa-caret-down"></i>
                          </div>
                        </FormGroup>
                      </Col>
                    );
                  } else if (el.type === "date") {
                    if (el.defaultDate) {
                      return (
                        <Col style={{ marginBottom: "10px" }}>
                          <FormGroup>
                            <Label
                              style={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              {el.name}
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              defaultValue={el.defaultDate}
                              onChange={(e) => changeInputValue(e, el.key)}
                              max="9999-12-31"
                              onPaste={(e) => onDatePaste(e, el.key)}
                            />
                          </FormGroup>
                        </Col>
                      );
                    } else {
                      return (
                        <Col style={{ marginBottom: "10px" }}>
                          <FormGroup>
                            <Label
                              style={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              {el.name}
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              defaultValue={inputText[el.key]}
                              onChange={(e) => changeInputValue(e, el.key)}
                              max="9999-12-31"
                              onPaste={(e) => onDatePaste(e, el.key)}
                            />
                          </FormGroup>
                        </Col>
                      );
                    }
                  } else if (el.type === "month") {
                    if (el.defaultDate) {
                      return (
                        <Col style={{ marginBottom: "10px" }}>
                          <FormGroup>
                            <Label
                              style={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              {el.name}
                            </Label>
                            <input
                              className="form-control"
                              type="month"
                              defaultValue={el.defaultDate}
                              max={el.max ? el.max : null}
                              onChange={(e) => changeInputMonthValue(e, el.key)}
                            />
                          </FormGroup>
                        </Col>
                      );
                    } else {
                      return (
                        <Col style={{ marginBottom: "10px" }}>
                          <FormGroup>
                            <Label
                              style={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              {el.name}
                            </Label>
                            <input
                              className="form-control"
                              type="month"
                              defaultValue={inputText[el.key]}
                              max={el.max ? el.max : null}
                              onChange={(e) => changeInputMonthValue(e, el.key)}
                            />
                          </FormGroup>
                        </Col>
                      );
                    }
                  } else if (el.type === "dropdown") {
                    return (
                      <Col style={{ marginBottom: "10px" }}>
                        <FormGroup>
                          <Label
                            style={{
                              color: "#000",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {el.name}
                          </Label>
                          <select
                            className="form-select"
                            defaultValue={el.data[0]?.key}
                            onChange={(e) => changeInputValue(e, el.key)}
                          >
                            {el.data?.map((item, i) => {
                              return (
                                <option value={item.key} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>
                    );
                  } else if (el.type === "checkbox") {
                    return (
                      <Col xs="auto" style={{ marginBottom: "10px" }}>
                        <FormGroup
                          style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Label
                            style={{
                              color: "#000",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {el.name}
                          </Label>
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              className="form-control"
                              type="checkbox"
                              onChange={(e) => changeCheckValue(e, el.key)}
                              style={{ height: "20px", width: "20px" }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    );
                  }
                })}
                <div
                  style={{
                    display: "flex",
                    width: "auto",
                    marginBottom: "10px",
                  }}
                >
                  <Col xs="auto" className="d-flex align-items-end">
                    <Button
                      style={{
                        backgroundColor: "#FF5913",
                        border: "none",
                      }}
                      onClick={() => callApi()}
                    >
                      {btntitle ? btntitle : "Search"}
                    </Button>
                  </Col>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    </Row>
  );
};
