import {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import "realgrid/dist/realgrid-style.css";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import { InputDefaultCell } from "./inputDefaultCell";
import { CheckDefaultCell } from "./checkDefaultCell";

const InputSearchGrid = ({
  editData,
  propsCloumn,
  searchColumns,
  setIds,
  ids,
  height,
}) => {
  const initialSort = [
    {
      // field: "ProductID",
      // dir: "asc",
    },
  ];
  const [data, setData] = useState(
    editData?.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );
  const [sort, setSort] = useState(initialSort);
  const [currentColumns, setCurrentColumns] = useState(propsCloumn);
  const [selectedState, setSelectedState] = useState({});
  const [filter, setFilter] = useState();
  const [widthObj, setWidthObj] = useState({});
  const [columns, setColumns] = useState(propsCloumn);
  const DATA_ITEM_KEY = "ProductID";
  const SELECTED_FIELD = "selected";
  const editField = "inEdit";
  const idGetter = getter(DATA_ITEM_KEY);
  const inputRef = useRef(null);
  useEffect(() => {
    setData(
      editData?.map((dataItem) =>
        Object.assign(
          {
            selected: false,
          },
          dataItem
        )
      )
    );
    setCurrentColumns(propsCloumn);
    setColumns(propsCloumn);
  }, [editData, propsCloumn]);

  const insertItem = (item) => {
    let el = data;
    item.ProductID = el?.length;
    item.inEdit = false;
    setData(el.unshift(item));
    return data;
  };

  const setWidth = (name, minW) => {
    if (widthObj[name]?.widthValue) {
      return widthObj[name]?.widthValue + 40;
    } else {
      return minW;
    }
  };
  useEffect(() => {
    let arr = [];
    Object.keys(selectedState)?.map((entrie, idx) => {
      if (selectedState[entrie]) {
        arr.push(entrie);
      }
    });
    setIds(arr);
  }, [selectedState]);

  // useEffect(() => {
  //   let obj = {};
  //   ids?.map((el) => {
  //     obj[el] = true;
  //   });
  //   setSelectedState(obj);
  // }, [ids]);

  const getItems = () => {
    let el = data;
    return el;
  };

  const updateItem = (item) => {
    let el = data;
    el?.map((map_item, i) => {
      if (map_item.ProductID === item.ProductID) {
        map_item = item;
        map_item.inEdit = false;
        return map_item;
      }
      return map_item;
    });
    return el;
  };

  const deleteItem = (item) => {
    let index = data.findIndex((record) => record.ProductID === item.ProductID);
    data.splice(index, 1);
    return data;
  };

  useEffect(() => {
    let newItems = getItems();
    setData(newItems);
  }, []);

  // modify the data in the store, db etc
  const remove = (dataItem) => {
    const newData = [...deleteItem(dataItem)];
    setData(newData);
  };

  const isNullFilter = (element) => {
    if (element.ProductID) {
      return true;
    }
  };

  const add = (dataItem) => {
    dataItem.inEdit = true;
    const newData = insertItem(dataItem);
    let filterArr = newData.filter(isNullFilter);
    setData(
      filterArr?.map((item) =>
        item.ProductID === dataItem.ProductID
          ? {
              ...item,
              inEdit: false,
            }
          : item
      )
    );
  };

  const update = (dataItem) => {
    dataItem.inEdit = false;
    const newData = updateItem(dataItem);
    setData(
      newData?.map((item) =>
        item.ProductID === dataItem.ProductID
          ? {
              ...item,
              inEdit: false,
            }
          : item
      )
    );
  };

  // Local state operations
  const discard = () => {
    const newData = [...data];
    newData.splice(0, 1);
    setData(newData);
  };
  const cancel = (dataItem) => {
    const originalItem = getItems().find(
      (p) => p.ProductID === dataItem.ProductID
    );
    const newData = data?.map((item) =>
      item.ProductID === originalItem.ProductID ? originalItem : item
    );
    setData(
      newData?.map((item) =>
        item.ProductID === dataItem.ProductID
          ? {
              ...item,
              inEdit: false,
            }
          : item
      )
    );
  };

  const enterEdit = (dataItem) => {
    setData(
      data?.map((item) =>
        item.ProductID === dataItem.ProductID
          ? {
              ...item,
              inEdit: true,
            }
          : item
      )
    );
  };

  const itemChange = (event) => {
    const newData = data?.map((item) =>
      item.ProductID === event.dataItem.ProductID
        ? {
            ...item,
            [event.field || ""]: event.value,
          }
        : item
    );
    setData(newData);
  };

  const addNew = () => {
    const newDataItem = {
      inEdit: true,
      Discontinued: false,
    };
    setData([newDataItem, ...data]);
  };

  useLayoutEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();
  });

  const filterChange = (e) => {
    let obj = {};
    if (e.filter?.filters === null || e.filter === null) {
      setData(filterBy(editData, e.filter));
      setFilter(e.filter);
    } else {
      for (let i = 0; i < e.filter.filters?.length; i++) {
        let field = e.filter.filters[i].field;
        let value = e.filter.filters[i].value;
        if (searchColumns.includes(field)) {
          obj[field] = value;
        }
      }
      setData(filterBy(editData, e.filter));
      setFilter(e.filter);
    }
  };

  useEffect(() => {
    if (data?.length === 0) {
      setData(editData);
      setColumns(propsCloumn);
      setCurrentColumns(propsCloumn);
    } else if (data?.length > 0) {
      setData(filterBy(editData, filter));
    }
  }, [editData]);

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const cellRender = (cell, props) => {
    if (props.dataItem.isRow) {
      if (props.field === "dailysalesname") {
        if (props.dataItem.row > 0) {
          return (
            <td rowSpan={props.dataItem.row}>
              {props.dataItem[props.field].toString()}
            </td>
          );
        } else {
          return null;
        }
      }
    }
    return cell;
  };

  const DefaultCell = (props) => (
    <InputDefaultCell
      {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
      widthObj={widthObj}
      setWidthObj={setWidthObj}
    />
  );
  const DefaultCheckCell = (props) => (
    <CheckDefaultCell
      {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
      widthObj={widthObj}
      setWidthObj={setWidthObj}
      ids={ids}
      setIds={setIds}
    />
  );

  //--------

  return (
    <div className="w-100">
      <Grid
        data={orderBy(data, sort)?.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        }))}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onItemChange={itemChange}
        editField={editField}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        sortable={false}
        filter={filter}
        onFilterChange={filterChange}
        resizable={true}
        reorderable={false}
        cellRender={cellRender}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
        style={height ? { maxHeight: height } : { maxHeight: "500px" }}
      >
        {columns?.map(
          (column, idx) =>
            column.show &&
            (column.field === "selected" ? (
              <Column
                key={idx}
                field={SELECTED_FIELD}
                title={column.title}
                filter={column.filter}
                hidden={column.hidden}
                locked={true}
                resizable={false}
                width="42px"
                headerSelectionValue={
                  data.findIndex((item) => !selectedState[idGetter(item)]) ===
                  -1
                }
              />
            ) : (
              <Column
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                hidden={column.hidden}
                locked={column.locked}
                cell={DefaultCell}
                headerSelectionValue={
                  column.field === "selected"
                    ? data.findIndex(
                        (item) => !selectedState[idGetter(item)]
                      ) === -1
                    : null
                }
              />
            ))
        )}
      </Grid>
      <br />
    </div>
  );
};

export default InputSearchGrid;
