import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { requestGet } from "../../apis/request";
import SearchBarCheap from "./SearchBarCheap";

const SearchBarWrapCheaps = ({ setInputValue, inputValue, type, height }) => {
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([]);
  const [myClients, setMyClients] = useState([]);
  const [mySales, setMySales] = useState([]);
  const timeout = useRef(null);

  const callSales = async () => {
    try {
      const res = await requestGet(`/api/v1/member/getOrganizationList`);
      setMySales(res);
    } catch (error) {
      alert("Request failed.");
    }
  };

  useEffect(() => {
    if (type === "sales") {
      callSales();
    }
  }, [type]);

  const updateField = (field, value, update = true) => {
    if (update) {
      if (value === "") {
        setKeyword("");
        setResults([]);
        clearTimeout(timeout.current);
      } else if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        if (type === "sku") {
          callSku(value);
        } else if (
          type === "soldTo" ||
          type === "shipTo" ||
          type === "soldToID" ||
          type === "shipToID"
        ) {
          callClients(value);
        } else if (type === "batch") {
          onNicknameSearch(value);
        } else if (type === "sales") {
          onSalesnameSearch(value);
        } else if (type === "nameEn") {
          callNameEn(value);
        } else if (type === "nameKr") {
          callNameKr(value);
        } else if (type === "orderNameCustom") {
          callCustom(value);
        }
      }, 300);
    }
    if (field === "keyword") {
      setKeyword(value);
      if (value === "") {
        setResults([]);
      }
    }
    if (field === "results") {
      setResults(value);
    }
  };

  const callClients = async (value) => {
    try {
      const params = new URLSearchParams();
      params.append("clientName", value);
      const res = await requestGet(`/api/v1/search/all/client/list?${params}`);
      setResults(res);
    } catch (error) {
      setResults([]);
    }
  };

  const callSku = async (value) => {
    if (value.length >= 1) {
      const params = new URLSearchParams();
      params.append("sku", value);
      const res = await requestGet(`/api/v1/search/all/product/list?${params}`);
      setResults(res);
    } else {
      setResults([]);
    }
  };

  const callNameEn = async (value) => {
    if (value.length >= 1) {
      let str = "";
      if (value.length > 0) {
        str += `nameEn=*${value.toUpperCase()}*`;
      }
      const params = new URLSearchParams();
      params.append("search", str);
      params.append("page", 0);
      params.append("size", 10);
      const res = await requestGet(`/api/v1/product/list/all?${params}`);
      setResults(res.content);
    } else {
      setResults([]);
    }
  };
  const callNameKr = async (value) => {
    if (value.length >= 1) {
      let str = "";
      if (value.length > 0) {
        str += `nameKr=*${value.toUpperCase()}*`;
      }
      const params = new URLSearchParams();
      params.append("search", str);
      params.append("page", 0);
      params.append("size", 10);
      const res = await requestGet(`/api/v1/product/list/all?${params}`);
      setResults(res.content);
    } else {
      setResults([]);
    }
  };

  const callCustom = async (value) => {
    if (value.length >= 1) {
      let str = "";
      if (value.length > 0) {
        str += `orderNameCustom=*${value}*`;
      }
      const params = new URLSearchParams();
      params.append("search", str);
      params.append("page", 0);
      params.append("size", 10);
      const res = await requestGet(`/api/v1/order/list/all?${params}`);
      setResults(res.content);
    } else {
      setResults([]);
    }
  };
  // useEffect(() => {
  //   if (inputValue) {
  //     setKeyword(inputValue);
  //   }
  // }, [inputValue]);

  const onUsernameSearch = (text) => {
    var results = myClients?.filter(
      (item) => true === matchName(item.client.username, text)
    );
    setResults(results);
  };
  const onNicknameSearch = (text) => {
    var results = myClients?.filter(
      (item) => true === matchName(item.client.nickname, text)
    );
    setResults(results);
  };
  const onSalesnameSearch = (text) => {
    var results = mySales?.filter(
      (item) => true === matchName(item.orgtx, text)
    );
    setResults(results);
  };

  const matchName = (name, keyword) => {
    var keyLen = keyword?.length;
    name = name.toLowerCase().substring(0, keyLen);
    if (keyword === "") return false;
    return name === keyword.toString().toLowerCase();
  };

  // useEffect(() => {
  //   if (codevalue) {
  //     setKeyword(String(codevalue));
  //   }
  // }, [codevalue]);

  return (
    <React.Fragment>
      <SearchBarCheap
        keyword={keyword}
        results={results}
        updateField={updateField}
        setInputValue={setInputValue}
        inputValue={inputValue}
        type={type}
        height={height}
      />
    </React.Fragment>
  );
};
export default withRouter(SearchBarWrapCheaps);
