import React, { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import "./mastermanagement.scss";
import {
  formPost,
  requestGet,
  requestJsonGet,
  requestPost,
} from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import GridTableNoPage from "../gridTable/GridTableNoPage";

const SystemSetting = () => {
  const [postfiles, setPostfiles] = useState([]);
  const [postfileIds, setPostfilesIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [data, setData] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [url, setUrl] = useState("");
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const history = useHistory();
  const [headerName] = useState(["name", "size"]);
  const [headerNameKr] = useState(["File Name", "File Size"]);
  const [gridColumns, setGridColumns] = useState([]);
  const [inputKey, setInputKey] = useState(Date.now());
  const [flag, setFlag] = useState(false);
  const childComponentRef = useRef();

  const callApi = async () => {
    try {
      const response = await requestJsonGet(`/api/admin/get/service/failure`);
      if (response) {
        setData(response);
        setFlag(true);
      }
    } catch (error) {
      alert("잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setContents(data.contents);
      setUrl(data.url);
    }
  }, [data]);

  const onFileHandler = (e) => {
    let arr = [];
    e.stopPropagation();
    let file = e.target.files;
    console.log(file);
    for (let i = 0; i < file.length; i++) {
      if (!postfileIds.includes(file[i].name)) {
        arr.push(file[i]);
      } else {
      }
    }
    setPostfiles(arr);
  };

  const onFileApi = async (e) => {
    if (window.confirm("등록 하시겠습니까?")) {
      try {
        setLoading(true);
        const params = new URLSearchParams();
        params.append("title", title);
        params.append("contents", contents);
        params.append("url", url);
        let data = params;
        let res = await requestPost(
          "/api/admin/register/service/failure",
          data
        );
        if (res.contentId && res.userId) {
          const param = new URLSearchParams();
          param.append("userId", res.userId);
          param.append("boardId", res.contentId);
          if (postfiles.length > 0) {
            var bodyFormData = new FormData();
            bodyFormData.append("file", postfiles[0]);
            await formPost(
              `/uploadFile/SERVICE_UNDER_MAINTENANCE/IMAGE?${param}`,
              bodyFormData
            );
          }
        }
        setLoading(false);
        alert("등록하였습니다.");
        window.location.reload();
      } catch (error) {
        setLoading(false);
        alert("등록 실패 하였습니다.");
      }
    }
  };

  const onDelete = async (e) => {
    if (window.confirm("삭제 하시겠습니까?")) {
      try {
        setLoading(true);
        const params = new URLSearchParams();
        params.append("title", title);
        params.append("contents", contents);
        params.append("url", url);
        let data = params;
        let res = await requestPost(
          "/api/admin/register/service/failure",
          data
        );
        // if (res.contentId && res.userId) {
        //   const param = new URLSearchParams();
        //   param.append("userId", res.userId);
        //   param.append("boardId", res.contentId);
        //   if (postfiles.length > 0) {
        //     var bodyFormData = new FormData();
        //     bodyFormData.append("file", postfiles[0]);
        //     await formPost(
        //       `/uploadFile/SERVICE_UNDER_MAINTENANCE/IMAGE?${param}`,
        //       bodyFormData
        //     );
        //   }
        // }
        setLoading(false);
        alert("삭제 하였습니다.");
        window.location.reload();
      } catch (error) {
        setLoading(false);
        alert("삭제 실패 하였습니다.");
      }
    }
  };

  useEffect(() => {
    let ids = [];
    let arr = [];
    if (postfiles.length > 0) {
      for (const el of postfiles) {
        ids.push(el.name);
        arr.push({
          name: el.name,
          size: el.size,
        });
      }
      setPostfilesIds(ids);
      setGridData(arr);
    }
  }, [postfiles]);

  const removelistImg = (name) => {
    setPostfiles([]);
    setPostfilesIds([]);
    setInputKey(Date.now());
  };

  const onTitle = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const onContents = (e) => {
    e.preventDefault();
    setContents(e.target.value);
  };

  const onURL = (e) => {
    e.preventDefault();
    setUrl(e.target.value);
  };
  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container fluid>
        <Row className="w-100 m-0 d-flex justify-content-center">
          <Col className="col-12 px-5">
            <Row className="m-0">
              <Row className="w-100 mx-0 my-0 p-0">
                <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                  시스템 점검 등록
                </Col>
              </Row>
              <div style={{ padding: "10px" }} />
            </Row>
            <Row className="w-100 m-0 mb-4">
              <Col>
                <Row>
                  <Col className="p-0">
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          제목
                          <Input
                            type="text"
                            value={title}
                            disabled={flag}
                            onChange={onTitle}
                          />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          메세지
                          <Input
                            type="textarea"
                            rows="3"
                            disabled={flag}
                            style={{ resize: "none" }}
                            value={contents}
                            onChange={onContents}
                          />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          외부 URL
                          <Input
                            type="text"
                            value={url}
                            disabled={flag}
                            onChange={onURL}
                          />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ display: "flex", flexDirection: "column" }}>
                        <Label
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          이미지
                        </Label>
                        <div style={flag ? { display: "none" } : null}>
                          <Label className="fileAddName">
                            <Input
                              type="file"
                              id="image"
                              key={inputKey}
                              onChange={onFileHandler}
                            />
                            <span
                              className="btn acceptBtn"
                              style={{
                                padding: "0.47rem 0.75rem",
                                borderRadius: "5px",
                                color: "#fff",
                              }}
                            >
                              Select files
                            </span>
                          </Label>
                          <Button
                            className="dangerBtn ms-2"
                            onClick={() => removelistImg()}
                          >
                            remove
                          </Button>
                        </div>
                      </Col>
                      {postfiles.length > 0 ? (
                        <div className="mx-0 mt-3">
                          <div style={{ display: "flex" }}>
                            <div>
                              <GridTableNoPage
                                editData={gridData}
                                setEditData={setGridData}
                                propsCloumn={gridColumns}
                                setEditId={setEditId}
                                setIds={setIds}
                                ids={ids}
                                ref={childComponentRef}
                                shop
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        width: "auto",
                        marginBottom: "10px",
                      }}
                    >
                      <Col xs="auto" className="d-flex align-items-end">
                        <div style={{ margin: "10px 0px", textAlign: "end" }}>
                          <Button
                            className="acceptBtn btn"
                            disabled={flag}
                            onClick={() => onFileApi()}
                          >
                            등록
                          </Button>
                          <Button
                            className="dangerBtn btn ms-2"
                            onClick={() => onDelete()}
                          >
                            삭제
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(SystemSetting);
