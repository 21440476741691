import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import { InputWrap } from "../Common/InputWrap";
import GridTable from "../gridTable/GridTable";
import GridTablecopy from "../gridTable/GridTablecopy";
import Tablecopy from "../gridTable/table/Tablecopy";
import moment from "moment";
import PaginationAPIWrap from "../Common/PaginationAPIWrap";
import GridTableNoPage from "../gridTable/GridTableNoPage";

const PeedList = () => {
  const [pageNumber, setpageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(1);
  const [lastpage, setLastpage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerName] = useState(["selected", "contents", "createdAt"]);
  const [headerNameKr] = useState(["selected", "내용", "날짜"]);
  const [gridColumns, setGridColumns] = useState([]);
  const [headerData] = useState([
    {
      Header: "닉네임",
      accessor: "nickname",
      width: 50,
      align: "right",
    },
    {
      Header: "전화번호",
      accessor: "phonenumber",
      width: 50,
      align: "right",
    },
    {
      Header: "성별",
      accessor: "maleorfemale",
    },
    {
      Header: "가입일",
      accessor: "signupdate",
    },
  ]);
  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const bottomref = useRef(null);
  const [maxH, setMaxH] = useState(0);

  const [inputKey] = useState([
    {
      name: "사용자 이름",
      key: "userName",
      type: "text",
    },
    {
      name: "키워드",
      key: "keyword",
      type: "text",
    },
  ]);
  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  const callApi = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj[isKey[i]]) {
            params.append(isKey[i], inputObj[isKey[i]]);
          }
        }
      }
      params.append("page", activeNum - 1);
      params.append("size", 20);
      const res = await requestGet(`/api/admin/feed/list?${params}`);
      setData(res.content);
      setLastpage(res.totalPages);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (lastpage !== null) {
      callApi();
    }
  }, [activeNum]);

  useEffect(() => {
    let arr = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        arr.push({
          ProductID: data[i].id,
          title: data[i].title,
          contents: data[i].contents,
          createdAt: moment(data[i].createdAt).format("YYYY-MM-DD HH:mm"),
          data: data[i],
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          bottomref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref, bottomref]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container fluid style={{ height: "100%", maxHeight: "100%" }}>
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <div id="headerTilteID">피드 목록</div>
                <div style={{ padding: "10px" }} />
                <InputWrap
                  keys={keys}
                  inputKey={inputKey}
                  inputObj={inputObj}
                  setInputObj={setInputObj}
                  callApi={callApi}
                />
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px" }} />
                    </div>
                    <div
                      id="tableWrapWidth"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: `${maxH}px`,
                      }}
                    >
                      <GridTableNoPage
                        editData={gridData}
                        setEditData={setGridData}
                        propsCloumn={gridColumns}
                        callApi={callApi}
                        setEditId={setEditId}
                        setIds={setIds}
                        ids={ids}
                        ref={childComponentRef}
                        push="/feed/detail"
                      />
                      {/* <Tablecopy columns={headerData} data={gridData} /> */}
                    </div>
                    <div className="w-100 mx-0 p-0" ref={bottomref}>
                      <Col
                        className="w-100 m-0 p-0 d-flex"
                        style={{
                          backgroundColor: "#F4F4F4",
                          maxWidth: "100%",
                        }}
                      >
                        <PaginationAPIWrap
                          pageNumber={pageNumber}
                          setpageNumber={setpageNumber}
                          lastpage={lastpage}
                          activeNum={activeNum}
                          setActiveNum={setActiveNum}
                        />
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(PeedList);
