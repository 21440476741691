import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import VersionGridTable from "../gridTable/VersionGridTable";

const VersionControl = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const topBarref = useRef(null);
  const btnref = useRef(null);
  const [headerName] = useState(["os", "ver", "verAction"]);
  const [headerNameKr] = useState(["OS", "버전", "적용"]);
  const [gridData, setGridData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [gridColumns, setGridColumns] = useState([]);

  const callApi = async () => {
    setLoading(true);
    try {
      const androidRes = await requestGet(`/api/anonymous/app/version/android`);
      const iosRes = await requestGet(`/api/anonymous/app/version/ios`);
      let androidData = { ...androidRes, name: "Android" };
      let iosData = { ...iosRes, name: "iOS" };
      setData([androidData, iosData]);
      setOriginData([androidData, iosData]);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    let arr = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        arr.push({
          ProductID: i + 1,
          os: data[i].name,
          ver: data[i].version,
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div id="headerTilteID">버전 관리</div>
                      <div style={{ padding: "10px" }} />
                    </div>
                    <Row>
                      <Col>
                        <VersionGridTable
                          editData={gridData}
                          setEditData={setGridData}
                          propsCloumn={gridColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          originData={originData}
                          now100
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(VersionControl);
