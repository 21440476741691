import * as React from "react";
import { Button } from "reactstrap";
import { requestPost } from "../../../apis/request";
export const VerActiveCell = (props) => {
  const { dataItem } = props;
  const field = props.field || "";

  const combinedStyle = {
    ...{ textAlign: "center" },
    ...props.style,
  };

  const changeVer = async () => {
    if (dataItem?.edited) {
      console.log(dataItem);
      if (dataItem?.os && dataItem?.ver) {
        try {
          const params = new URLSearchParams();
          if (dataItem?.os === "Android") {
            params.append("os", "android");
          } else if (dataItem?.os === "iOS") {
            params.append("os", "ios");
          }
          if (dataItem?.ver) {
            params.append("version", dataItem.ver);
          }
          await requestPost(`/api/admin/app/version/create?${params}`);
          alert("적용되었습니다.");
        } catch (error) {
          console.log(error);
          alert("요청 실패 하였습니다.");
        }
      }
    } else {
      alert("현재 버전과 동일합니다.");
    }
  };

  return (
    <td style={combinedStyle} className={props.className}>
      <Button color="success" onClick={() => changeVer()}>
        적용
      </Button>
    </td>
  );
};
