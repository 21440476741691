import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";
import logoSm from "../../assets/icons/logo.svg";
import logo from "../../assets/icons/logo1.png";

import HeaderRun from "./Header.run";
import ProfileMenu from "./ProfileMenu";

const Header = (props) => {
  const history = useHistory();
  const [headerText, setHeaderText] = useState("");

  useEffect(() => {
    setHeaderText(document.getElementById("headerTilteID")?.innerText);
    HeaderRun();
  }, []);

  const toggleCollapsed = (e) => {
    e.preventDefault();
    props.actions.toggleSetting("isCollapsed");
    resize();
  };

  const toggleAside = (e) => {
    e.preventDefault();
    props.actions.toggleSetting("asideToggled");
  };

  const resize = () => {
    // all IE friendly dispatchEvent
    var evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  };

  return (
    <header className="topnavbar-wrapper">
      {/* START Top Navbar */}
      <nav
        className="navbar topnavbar"
        style={{ backgroundColor: "#1a1a1a", backgroundImage: "none" }}
      >
        {/* START navbar header */}
        <div className="d-flex">
          <div
            className="navbar-header"
            style={{
              margin: 0,
              padding: 0,
              backgroundColor: "#121e2d",
              borderBottom: "1px solid",
            }}
          >
            <div className="navbar-brand" style={{ margin: "0px 10px" }}>
              <div
                className="brand-logo"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/")}
              >
                {/* <img className="img-fluid" src={logo} alt="App Logo" /> */}
              </div>
              <div
                className="brand-logo-collapsed"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/")}
              >
                {/* <img className="img-fluid" src={logoSm} alt="App Logo" /> */}
              </div>
            </div>
          </div>
          {/* END navbar header */}

          {/* START Left navbar */}
          <ul className="navbar-nav mr-auto flex-row">
            <li className="nav-item">
              {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <a
                href=""
                className="nav-link d-none d-md-block d-lg-block d-xl-block"
                onClick={(e) => toggleCollapsed(e)}
              >
                <em className="fas fa-bars"></em>
              </a>
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <a
                href=""
                className="nav-link sidebar-toggle d-md-none"
                onClick={(e) => toggleAside(e)}
              >
                <em className="fas fa-bars"></em>
              </a>
            </li>
          </ul>
          <div
            className="MasterTitle d-flex align-items-center"
            style={{
              color: "#fff",
              cursor: "default",
              marginLeft: "20px",
              fontWeight: "500",
            }}
          >
            {headerText}
          </div>
        </div>
        <div
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <ProfileMenu />
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
