import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import {
  adminRequestPost,
  requestDelete,
  requestGet,
  requestPost,
} from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import GridTable from "../gridTable/GridTable";
import Slidewithfade from "../Common/Slidewithfade";
import { InputWrap } from "../Common/InputWrap";
import moment from "moment";

const Recommendskeyword = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [clubData, setClubData] = useState(null);
  const [favoriteData, setFavoriteData] = useState(null);
  const [feedData, setFeedData] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [gridClubData, setGridClubData] = useState([]);
  const [gridFavoriteData, setGridFavoriteData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerClubName] = useState(["keyword", "date", "delCell"]);
  const [headerClubNameKr] = useState(["키워드", "날짜", "삭제하기"]);
  const [gridClubColumns, setGridClubColumns] = useState([]);
  const [gridFavoriteColumns, setGridFavoriteColumns] = useState([]);
  // const [userId] = useState(props.location.state.id);
  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);
  const [subTitle, setSubTitle] = useState("userreport");

  const [infoArr, setInfoArr] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [btnArr] = useState(["userreport", "contentsreport"]);

  const [inputKey] = useState([]);

  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  const callApi = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj[isKey[i]]) {
            params.append(isKey[i], inputObj[isKey[i]]);
          }
        }
      }
      const response = await requestGet(
        `/api/admin/recommends/keyword?${params}`
      );
      setData(response);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  const addKeyword = async () => {
    if (window.confirm("등록 하시겠습니까?")) {
      try {
        let keyword = window.prompt();
        let body = { keyword: keyword };
        await adminRequestPost(`/api/admin/recommends/keyword`, body);
        alert("등록 하였습니다.");
        callApi();
      } catch (error) {
        console.log(error);
        alert("등록 실패 하였습니다.");
      }
    }
  };

  const onKeywordDelete = async (id) => {
    if (window.confirm("정말 삭제 하시겠습니까?")) {
      try {
        await requestDelete(`/api/admin/recommends/keyword/${id}`);
        alert("삭제 성공 하였습니다.");
        callApi();
      } catch (error) {
        console.log(error);
        alert("삭제 실패 하였습니다.");
      }
    }
  };

  useEffect(() => {
    let arr = [];
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        arr.push({
          ProductID: data[i]?.id,
          id: data[i]?.id,
          keyword: data[i]?.keyword,
          registedAt: data[i]?.registedAt,
          date: data[i]?.registedAt
            ? moment(data[i].registedAt).format("YYYY-MM-DD")
            : "",
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerClubName?.length > 0 && headerClubNameKr?.length > 0) {
      for (let i = 0; i < headerClubName?.length; i++) {
        arr.push({
          title: headerClubNameKr[i],
          field: headerClubName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridClubColumns(arr);
    }
  }, [headerClubName, headerClubNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref, subTitle]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    추천 검색어
                  </Col>
                </Row>
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div
                        style={{
                          display: "flex",
                          width: "auto",
                          padding: "10px 0px",
                        }}
                      >
                        <Col xs="auto" className="d-flex align-items-end">
                          <Button id="trueBtn" onClick={() => callApi()}>
                            Search
                          </Button>
                        </Col>
                        <Col
                          xs="auto"
                          className="d-flex align-items-end"
                          style={{ margin: "0px 10px" }}
                        >
                          <Button id="trueBtn" onClick={() => addKeyword()}>
                            등록하기
                          </Button>
                        </Col>
                      </div>
                    </div>
                    <div
                      id="tableWrapWidth"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: `${maxH}px`,
                      }}
                    >
                      <GridTable
                        editData={gridData}
                        setEditData={setGridData}
                        propsCloumn={gridClubColumns}
                        callApi={callApi}
                        setEditId={setEditId}
                        setIds={setIds}
                        ids={ids}
                        ref={childComponentRef}
                        delApi={onKeywordDelete}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(Recommendskeyword);
