import * as React from "react";
import { useHistory } from "react-router";
export const PageDefaultCell = (props) => {
  const { dataItem, push } = props;
  const field = props.field || "";
  const fdgrid = React.useRef(null);
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  const history = useHistory();

  const combinedStyle = {
    ...(push ? { cursor: "pointer" } : {}),
    ...props.style,
  };

  const onHistory = () => {
    if (push) {
      history.push({
        pathname: push,
        state: {
          data: dataItem.data,
        },
      });
    }
  };

  return (
    <td
      style={combinedStyle}
      className={props.className}
      onDoubleClick={() => onHistory()}
    >
      {field === "thumbnail" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={dataValue} alt="사진" style={{ maxWidth: "150px" }} />
        </div>
      ) : (
        <span ref={fdgrid}>{dataValue === "null" ? "" : dataValue}</span>
      )}
    </td>
  );
};
