import axios from "axios";
import { getCookie, rmCookie } from "../cookie";
//API 주소 입니다 supUrl = 테스트 서버 prod = 운영서버 입니다.
// 13 번째 줄에 적용해주시면 됩니다.
// const comUrl = "http://192.168.0.22:8088";
// const subUrl = "http://192.168.0.20:8088";
const supUrl = "http://call.golfrock.co.kr:8088";
// const supsubUrl = "http://52.78.38.141:9088";
const prod = "https://call.golfrock.co.kr";
// const host = window.location.host;
// const origin = `${window.location.origin}:8088`;
// let flag = false;
axios.defaults.withCredentials = true;
export const apiUrl =
  window.location.hostname === "call.golfrock.co.kr" ? prod : supUrl;
// export const apiUrl = supUrl;
// export const apiUrl = host === "localhost:3000" ? subUrl : origin;

export const setToken = (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const form = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "multipart/form-data",
    withCredentials: true,
  },
});

export const client = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
});

export const update = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

// form.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       if (!flag) {
//         if (window.location.pathname !== "/login") {
//           flag = true;
//           alert("로그인이 만료 되었습니다.");
//           requestGet("/api/v1/account/logout");
//           rmCookie("golfToken");
//           localStorage.removeItem("userNick");
//           localStorage.removeItem("userType");
//           localStorage.removeItem("pmgId");
//           localStorage.removeItem("roleName");
//           window.location.href = "/login";
//         }
//       }
//     } else if (error.response.status === 404) {
//       window.location.href = "/404page";
//     } else if (error.response.status === 403) {
//       alert(
//         "Access Denied. You do not have the necessary permissions to perform this action."
//       );
//       if (localStorage.getItem("userType") === "CLIENT") {
//         return (window.location.href = "/shop");
//       } else {
//         return (window.location.href = "/dashboard");
//       }
//     } else if (error.response.status < 200 || error.response.status > 299) {
//       return alert(error.response.data.message);
//     }
//   }
// );

// client.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       if (!flag) {
//         if (window.location.pathname !== "/login") {
//           flag = true;
//           alert("로그인 만료 되었습니다.");
//           requestGet("/api/v1/account/logout");
//           rmCookie("golfToken");
//           localStorage.removeItem("userNick");
//           localStorage.removeItem("userType");
//           localStorage.removeItem("pmgId");
//           localStorage.removeItem("roleName");
//           window.location.href = "/login";
//         }
//       }
//     } else if (error.response.status === 403) {
//       alert(
//         "Access Denied. You do not have the necessary permissions to perform this action."
//       );
//       if (localStorage.getItem("userType") === "CLIENT") {
//         return (window.location.href = "/shop");
//       } else {
//         return (window.location.href = "/dashboard");
//       }
//     } else if (error.response.status === 404) {
//       window.location.href = "/404page";
//     }
//   }
// );

// update.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       if (!flag) {
//         if (window.location.pathname !== "/login") {
//           flag = true;
//           alert("로그인 만료 되었습니다.");
//           requestGet("/api/v1/account/logout");
//           rmCookie("golfToken");
//           localStorage.removeItem("userNick");
//           localStorage.removeItem("userType");
//           localStorage.removeItem("pmgId");
//           localStorage.removeItem("roleName");
//           window.location.href = "/login";
//         }
//       }
//     } else if (error.response.status === 404) {
//       window.location.href = "/404page";
//     } else if (error.response.status === 403) {
//       alert(
//         "Access Denied. You do not have the necessary permissions to perform this action."
//       );
//       if (localStorage.getItem("userType") === "CLIENT") {
//         return (window.location.href = "/shop");
//       } else {
//         return (window.location.href = "/dashboard");
//       }
//     } else if (error.response.status < 200 || error.response.status > 299) {
//       return alert(error.response.data.message);
//     }
//   }
// );

export const loginPost = async (url, body) => {
  try {
    const res = await client.post(url, body, {
      withCredentials: true,
    });
    if (res.status >= 200 && res.status <= 299) {
      // const responseHeaders = {
      //   "Content-Type": "application/json",
      // "set-cookie": [`authToken=${res.data.token}; Path=/; HttpOnly;`],
      // };
      // res.writeHead(200, responseHeaders);
      return res.data;
    } else {
      let message;
      if (res.status) {
        switch (res.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = res.message;
            break;
        }
      }
      alert(message);
    }
  } catch (error) {
    // alert("아이디와 비밀번호를 확인해주세요");
  }
};

export const requestPost = async (url, body) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await client.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 409:
          message = "요청이 존재합니다.";
          break;
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message = res.message;
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
};

export const formPost = async (url, body) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await form.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 409:
          message = "요청이 존재합니다.";
          break;
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = "";
          break;
      }
    }
    return alert(message);
  }
};

export const adminRequestPost = async (url, body) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await update.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestPatch = async (url, body) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await client.patch(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 409:
          message = "요청이 존재합니다.";
          break;
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message = res.message;
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
};

export const requestPut = async (url, body) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await client.put(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const adminUserPut = async (url, body) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await update.put(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestGet = async (url) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await client.get(url, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestJsonGet = async (url) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await update.get(url, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestDelete = async (url) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await client.delete(url, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const imgPut = async (url, body) => {
  const token = getCookie("golfToken");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await form.put(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};
