import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import {
  adminRequestPost,
  adminUserPut,
  requestGet,
  requestPut,
} from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import GridTable from "../gridTable/GridTable";
import Slidewithfade from "../Common/Slidewithfade";
import { InputWrap } from "../Common/InputWrap";
import moment from "moment";

const RecommandUser = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [clubData, setClubData] = useState(null);
  const [favoriteData, setFavoriteData] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [gridUserData, setGridUserData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerName] = useState([
    "nickname",
    "number",
    "gender",
    "signdate",
    "lastdate",
    "delBtn",
  ]);
  const [headerNameKr] = useState([
    "닉네임",
    "전화번호",
    "성별",
    "가입일",
    "최근 접속일",
    "삭제하기",
  ]);
  const [headerUserName, setHeaderUserName] = useState([
    "nickname",
    "number",
    "gender",
    "signdate",
    "lastdate",
    "addBtn",
  ]);
  const [headerUserNameKr, setHeaderUserNameKr] = useState([
    "닉네임",
    "전화번호",
    "성별",
    "가입일",
    "최근 접속일",
    "등록하기",
  ]);
  const [gridColumns, setGridColumns] = useState([]);
  const [gridUserColumns, setGridUserColumns] = useState([]);
  // const [userId] = useState(props.location.state.id);
  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);
  const [subTitle, setSubTitle] = useState("default");

  const [infoArr, setInfoArr] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [btnArr] = useState(["default", "signup"]);

  const [inputKey] = useState([
    {
      name: "이름",
      key: "userName",
      type: "text",
    },
    {
      name: "전화번호",
      key: "phone",
      type: "text",
    },
    {
      name: "성별",
      key: "gender",
      type: "dropdown",
      data: [
        { key: "", value: "전체" },
        { key: "MALE", value: "남자" },
        { key: "FEMALE", value: "여자" },
      ],
    },
    {
      name: "정렬",
      key: "sort",
      type: "dropdown",
      data: [
        { key: "logindate", value: "최근 접속일 순" },
        { key: "signupdate", value: "최근 가입순" },
        { key: "longsignup", value: "오래된 가입순" },
        { key: "point", value: "포인트 많은 순" },
      ],
    },
  ]);

  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);

  const [inputKey1] = useState([
    {
      name: "이름",
      key: "userName",
      type: "text",
    },
    {
      name: "전화번호",
      key: "phone",
      type: "text",
    },
    {
      name: "성별",
      key: "gender",
      type: "dropdown",
      data: [
        { key: "", value: "전체" },
        { key: "MALE", value: "남자" },
        { key: "FEMALE", value: "여자" },
      ],
    },
    {
      name: "정렬",
      key: "order",
      type: "dropdown",
      defaultValue: "lastLogin",
      data: [
        { key: "lastLogin", value: "최근 접속일 순" },
        { key: "recentJoin", value: "최근 가입순" },
        { key: "oldJoin", value: "오래된 가입순" },
        { key: "point", value: "포인트 많은 순" },
      ],
    },
  ]);
  const [inputObj1, setInputObj1] = useState({});
  const [keys1, setKeys1] = useState([]);

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  useEffect(() => {
    let arr = [];
    inputKey1?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys1(arr);
  }, [inputKey1]);

  const callApi = async () => {
    setLoading(true);
    try {
      const userResponse = await requestGet(`/api/admin/recommends/user`);
      setData(userResponse);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  const callApi1 = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj1);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj1[isKey[i]]) {
            params.append(isKey[i], inputObj1[isKey[i]]);
          }
        }
      }
      params.append("page", 0);
      params.append("size", 2147483647);
      const res = await requestGet(`/api/admin/user/list?${params}`);
      setUserData(res.content);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    let arr = [];
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        arr.push({
          ProductID: data[i]?.id,
          userId: data[i]?.userId,
          nickname: data[i]?.userProfile?.nickname,
          number: data[i]?.userProfile?.phone,
          gender: data[i]?.userProfile?.gender,
          registerDate: data[i]?.userProfile?.registerDate,
          signdate: data[i]?.userProfile?.registerDate
            ? moment(data[i]?.userProfile?.registerDate).format("YYYY-MM-DD")
            : "",
          lastdate: data[i]?.userProfile?.lastLoginDate
            ? moment(data[i]?.userProfile?.lastLoginDate).format("YYYY-MM-DD")
            : "",
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  useEffect(() => {
    let arr = [];
    if (userData?.length > 0) {
      for (let i = 0; i < userData?.length; i++) {
        arr.push({
          ProductID: userData[i]?.id,
          userId: userData[i]?.userId,
          nickname: userData[i]?.nickname,
          number: userData[i]?.phone,
          gender: userData[i]?.gender,
          registerDate: userData[i]?.registerDate,
          signdate: userData[i]?.registerDate
            ? moment(userData[i]?.registerDate).format("YYYY-MM-DD")
            : "",
          lastdate: userData[i]?.lastLoginDate
            ? moment(userData[i]?.lastLoginDate).format("YYYY-MM-DD")
            : "",
        });
      }
    }
    setGridUserData(arr);
  }, [userData]);

  useEffect(() => {
    let arr = [];
    if (headerUserName?.length > 0 && headerUserNameKr?.length > 0) {
      for (let i = 0; i < headerUserName?.length; i++) {
        arr.push({
          title: headerUserNameKr[i],
          field: headerUserName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridUserColumns(arr);
    }
  }, [headerUserName, headerUserNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref, subTitle]);

  const changeSubTitle = (value) => {
    setSubTitle(value);
  };

  // const onDelete = async () => {
  //   if (window.confirm("정말 삭제 하시겠습니까?")) {
  //     try {
  //       await requestPut(`/api/admin/recommends/user/$`);
  //     } catch (error) {
  //       console.log(error);
  //       alert("삭제 실패 하였습니다.");
  //     }
  //   }
  // };

  const onAddBtn = async (id, item) => {
    if (window.confirm("등록 하시겠습니까?")) {
      try {
        let body = item;
        await adminRequestPost(`/api/admin/recommends/user`, body);
        alert("등록 하였습니다.");
        callApi();
      } catch (error) {
        console.log(error);
        alert("등록 실패 하였습니다.");
      }
    }
  };

  const onDelete = async (id, item) => {
    if (window.confirm("정말 삭제 하시겠습니까?")) {
      try {
        let body = item;
        await adminUserPut(`/api/admin/recommends/user/${id}`, body);
        alert("삭제 성공 하였습니다.");
        callApi();
      } catch (error) {
        console.log(error);
        alert("삭제 실패 하였습니다.");
      }
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    추천 사용자
                  </Col>
                </Row>
                <div style={{ padding: "10px" }} />
                <Row className="w-100 m-0 p-0">
                  {btnArr?.map((btn, i) => {
                    return (
                      <Col
                        xs="auto"
                        className="d-flex align-items-end p-0"
                        key={i}
                      >
                        <Button
                          id={subTitle === btn ? "trueBtn" : "falseBtn"}
                          onClick={() => changeSubTitle(btn)}
                        >
                          {btn === "default"
                            ? "목록"
                            : btn === "signup"
                            ? "등록"
                            : ""}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
                {subTitle === "signup" ? (
                  <InputWrap
                    keys={keys1}
                    inputKey={inputKey1}
                    inputObj={inputObj1}
                    setInputObj={setInputObj1}
                    callApi={callApi1}
                  />
                ) : (
                  <Col
                    xs="auto"
                    className="d-flex align-items-end p-0"
                    style={{ marginTop: "10px" }}
                  >
                    <Button id="trueBtn" onClick={() => callApi()}>
                      Search
                    </Button>
                  </Col>
                )}
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px 0px" }}></div>
                    </div>
                    {subTitle === "default" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridData}
                          setEditData={setGridData}
                          propsCloumn={gridColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                          deleteapi={onDelete}
                        />
                      </div>
                    ) : subTitle === "signup" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridUserData}
                          setEditData={setGridUserData}
                          propsCloumn={gridUserColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                          addapi={onAddBtn}
                        />
                      </div>
                    ) : (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridData}
                          setEditData={setGridData}
                          propsCloumn={gridColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                          deleteapi={onDelete}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(RecommandUser);
