import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { ReactComponent as User } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-user.svg";
import { rmCookie } from "../../cookie";

const ProfileMenu = (props) => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("");

  useEffect(() => {
    setusername(localStorage.getItem("userNick"));
  }, []);

  const onLogOut = async () => {
    rmCookie("golfToken");
    localStorage.removeItem("userId");
    history.push("/login");
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <User
            style={{
              width: "24px",
              height: "24px",
              fill: "#fff",
            }}
          />
          <span className="ms-2 me-1" style={{ color: "#fff" }}>
            {/* {username} */}
          </span>
          <i className="mdi mdi-chevron-down" style={{ color: "#fff" }} />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem className="dropdown-item" onClick={onLogOut}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(connect()(withTranslation()(ProfileMenu)));
