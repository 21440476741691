import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import { InputWrap } from "../Common/InputWrap";
import GridTable from "../gridTable/GridTable";

const ProfileAccept = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerName] = useState([
    "nickname",
    "phone",
    "gender",
    "startdate",
    "lastdate",
  ]);
  const [headerNameKr] = useState([
    "닉네임",
    "전화번호",
    "성별",
    "프로필 변경 신청일",
    "최근 변경일",
  ]);
  const [gridColumns, setGridColumns] = useState([]);

  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);

  const [inputKey] = useState([
    {
      name: "이름",
      key: "userName",
      type: "text",
    },
    {
      name: "전화번호",
      key: "phone",
      type: "text",
    },
    {
      name: "성별",
      key: "gender",
      type: "dropdown",
      data: [
        { key: "", value: "전체" },
        { key: "MALE", value: "남자" },
        { key: "FEMALE", value: "여자" },
      ],
    },
  ]);

  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  const callApi = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj[isKey[i]]) {
            params.append(isKey[i], inputObj[isKey[i]]);
          }
        }
      }
      params.append("page", 0);
      params.append("size", 2147483647);
      const res = await requestGet(
        `/api/admin/profile/wait/confirm/list?${params}`
      );
      setData(res.content);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    let arr = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        arr.push({
          ProductID: i + 1,
          nickname: data[i].nickname,
          phone: data[i].phone,
          gender: data[i].gender,
          startdate: data[i].startdate,
          lastdate: data[i].lastdate,
          data: data[i],
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <div id="headerTilteID">프로필 승인 대기</div>
                <div style={{ padding: "10px" }} />
                <InputWrap
                  keys={keys}
                  inputKey={inputKey}
                  inputObj={inputObj}
                  setInputObj={setInputObj}
                  callApi={callApi}
                />
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px" }} />
                    </div>
                    <div
                      id="tableWrapWidth"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: `${maxH}px`,
                      }}
                    >
                      <GridTable
                        editData={gridData}
                        setEditData={setGridData}
                        propsCloumn={gridColumns}
                        callApi={callApi}
                        setEditId={setEditId}
                        setIds={setIds}
                        ids={ids}
                        push="/profile/detail"
                        ref={childComponentRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(ProfileAccept);
