import React, { useState } from "react";
import { Row, Col, Card, Alert, Container } from "reactstrap";
import MetaTags from "react-meta-tags";

import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { ReactComponent as Loginimg } from "../../assets/images/loginimg.svg";

const SignUp = (props) => {
  const [id, setId] = useState("");
  const [nick, setNick] = useState("");
  const [pw, setPw] = useState("");
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    // props.registerUser(values);
  };

  // useEffect(() => {
  //   props.apiError("");
  //   document.body.className = "authentication-bg";
  //   // remove classname when component will unmount
  //   return function cleanup() {
  //     document.body.className = "";
  //   };
  // });

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Samply - React Admin & Dashboard Template</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="text-center mb-5">
                <Link to="/" className="auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="28"
                    className="auth-logo-dark"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="28"
                    className="auth-logo-light"
                  />
                </Link>
                <p className="font-size-15 text-muted mt-3">
                  스마트플러그 IOT 모니터링 서비스
                </p>
              </div>
              <Card className="overflow-hidden" style={{ border: "none" }}>
                <Row className="g-0">
                  <Col lg={6}>
                    <div className="p-lg-5 p-4">
                      <div>
                        <div className="titleLineWrap">
                          <h5 className="m-0" style={{ width: "100px" }}>
                            회원가입
                          </h5>
                          <div className="titleLine" />
                        </div>
                        <p className="text-muted">
                          Sign up to continue to Samply.
                        </p>
                      </div>

                      <div className="mt-4 pt-3">
                        <form
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          {props.user && props.user ? (
                            <Alert color="success">
                              Register User Successfully
                            </Alert>
                          ) : null}

                          {props.registrationError &&
                          props.registrationError ? (
                            <Alert color="danger">
                              {props.registrationError}
                            </Alert>
                          ) : null}

                          <div className="mb-3">
                            <input
                              id="email"
                              name="email"
                              label="아이디"
                              className="form-control"
                              placeholder="아이디를 입력하세요."
                              type="text"
                              value={id}
                              onChange={(e) => setId(e.target.value)}
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <input
                              name="username"
                              label="닉네임"
                              type="text"
                              value={nick}
                              onChange={(e) => setNick(e.target.value)}
                              required
                              placeholder="닉네임을 입력하세요."
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              name="password"
                              label="비밀번호"
                              type="password"
                              value={pw}
                              onChange={(e) => setPw(e.target.value)}
                              required
                              placeholder="비밀번호를 입력하세요."
                            />
                          </div>

                          <div className="mt-4 text-center">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              style={{
                                backgroundColor: "#7298ff",
                                border: "none",
                              }}
                              type="submit"
                            >
                              가입하기
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <p>
                              계정이 이미 있으신가요?
                              <Link
                                to="login"
                                className="fw-semibold text-decoration-none"
                              >
                                {" "}
                                로그인{" "}
                              </Link>{" "}
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="p-xxl-5 p-xl-4 h-100 d-none d-lg-block">
                      <Loginimg style={{ width: "100%", height: "100%" }} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SignUp;
