import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import { InputWrap } from "../Common/InputWrap";
import GridTable from "../gridTable/GridTable";

const BannerList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerName] = useState(["title", "contents", "thumbnailed", "fileed"]);
  const [headerNameKr] = useState(["제목", "내용", "썸네일", "배너 이미지"]);
  const [gridColumns, setGridColumns] = useState([]);

  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);
  const history = useHistory();

  const [inputKey] = useState([
    {
      name: "제목",
      key: "title",
      type: "text",
    },
    {
      name: "내용",
      key: "content",
      type: "text",
    },
    {
      name: "날짜 from",
      key: "targetDateFrom",
      type: "date",
    },
    {
      name: "날짜 to",
      key: "targetDateTo",
      type: "date",
    },
  ]);
  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  const callApi = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj[isKey[i]]) {
            params.append(isKey[i], inputObj[isKey[i]]);
          }
        }
      }
      params.append("page", 0);
      params.append("size", 2147483647);
      const res = await requestGet(`/api/admin/banner/list?${params}`);
      setData(res.content);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  useEffect(() => {
    let arr = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        arr.push({
          contentId: data[i]?.contentId,
          contents: data[i]?.contents,
          createAt: data[i]?.createAt,
          fileUrl: data[i]?.fileUrl,
          fileed: data[i]?.fileUrl ? "O" : "X",
          files: data[i]?.files,
          outsideUrl: data[i]?.outsideUrl,
          targetDate: data[i]?.targetDate,
          targetEndDate: data[i]?.targetEndDate,
          thumbnailUrl: data[i]?.thumbnailUrl,
          thumbnailed: data[i]?.thumbnailUrl ? "O" : "X",
          thumbnails: data[i]?.thumbnails,
          title: data[i]?.title,
          updateAt: data[i]?.updateAt,
          userId: data[i]?.userId,
        });
      }
    }
    setGridData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <div id="headerTilteID">배너 목록</div>
                <div style={{ padding: "10px" }} />
                <InputWrap
                  keys={keys}
                  inputKey={inputKey}
                  inputObj={inputObj}
                  setInputObj={setInputObj}
                  callApi={callApi}
                />
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <Button
                        onClick={() => history.push("/banner/upload")}
                        style={{
                          margin: "10px 0px",
                          backgroundColor: "#FF5913",
                          border: "none",
                        }}
                      >
                        배너등록
                      </Button>
                    </div>
                    <div
                      id="tableWrapWidth"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: `${maxH}px`,
                      }}
                    >
                      <GridTable
                        editData={gridData}
                        setEditData={setGridData}
                        propsCloumn={gridColumns}
                        callApi={callApi}
                        setEditId={setEditId}
                        setIds={setIds}
                        ids={ids}
                        push="/banner/detail"
                        ref={childComponentRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(BannerList);
