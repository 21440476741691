import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "reactstrap";
export const VersionCell = (props) => {
  const { dataItem, setEditData, editData, originData } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  const [value, setValue] = React.useState("");
  const [flag, setFlag] = React.useState(false);

  const onChange = () => {
    if (flag) {
      setEditData(
        editData.map((item, i) => {
          if (item.ProductID === dataItem.ProductID) {
            item.ver = value;
            if (originData[i]?.version === value) {
              item.edited = false;
            } else {
              item.edited = true;
            }
            return item;
          }
          return item;
        })
      );
      setFlag(false);
    }
  };

  const changeValue = (e) => {
    setFlag(true);
    setValue(e.target.value);
  };

  return (
    <td style={props.style} className={props.className}>
      <input
        className="form-control"
        type="text"
        defaultValue={dataItem.ver}
        onChange={(e) => changeValue(e)}
        onBlur={() => onChange()}
      />
    </td>
  );
};
