import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestGet } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import GridTable from "../gridTable/GridTable";
import Slidewithfade from "../Common/Slidewithfade";
import { InputWrap } from "../Common/InputWrap";
import moment from "moment";

const Chating = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [clubData, setClubData] = useState(null);
  const [favoriteData, setFavoriteData] = useState(null);
  const [feedData, setFeedData] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridClubData, setGridClubData] = useState([]);
  const [gridFavoriteData, setGridFavoriteData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerClubName] = useState([
    "kind",
    "user1Nickname",
    "user2Nickname",
    "user3Nickname",
    "user4Nickname",
    "lastMessageDate",
    "lastMessage",
    "chatdown",
  ]);
  const [headerClubNameKr] = useState([
    "종류",
    "닉네임1",
    "닉네임2",
    "닉네임3",
    "닉네임4",
    "마지막 대화 날짜",
    "마지막 메세지",
    "다운로드",
  ]);
  const [gridClubColumns, setGridClubColumns] = useState([]);
  const [gridFavoriteColumns, setGridFavoriteColumns] = useState([]);
  // const [userId] = useState(props.location.state.id);
  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);
  const [subTitle, setSubTitle] = useState("userreport");

  const [infoArr, setInfoArr] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [btnArr] = useState(["userreport", "contentsreport"]);

  const [inputKey] = useState([
    {
      name: "사용자",
      key: "user",
      type: "text",
    },
  ]);

  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  const callApi = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj[isKey[i]]) {
            params.append(isKey[i], inputObj[isKey[i]]);
          }
        }
      }
      const response = await requestGet(`/api/admin/chatting/list?${params}`);
      const response1 = await requestGet(
        `/api/admin/chatting/message/b99f59bdfdee43b5955ce8c5ddeba3a3/list`
      );
      setData(response?.content);
      setLoading(false);
    } catch (error) {
      setData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (userId) {
  //     callApi();
  //   }
  // }, [userId]);

  useEffect(() => {
    let arr = [];
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        arr.push({
          ProductID: data[i]?.id,
          anonymous: data[i]?.anonymous,
          applName: data[i]?.applName,
          bookCC: data[i]?.bookCC,
          bookExtraInfo: data[i]?.bookExtraInfo,
          bookingCode: data[i]?.bookingCode,
          bookingDate: data[i]?.bookingDate,
          bookingDateTime: data[i]?.bookingDateTime,
          bookingTime: data[i]?.bookingTime,
          chatCode: data[i]?.chatCode,
          chatType: data[i]?.chatType,
          kind:
            data[i]?.chatType === "FOLLOW"
              ? "친구"
              : data[i]?.chatType === "ANONYMOUS"
              ? "익명"
              : data[i]?.chatType === "BOOKING"
              ? "예약"
              : data[i]?.chatType === "BOOKING_1"
              ? "예약1"
              : data[i]?.chatType === "BOOKING_2"
              ? "대기"
              : data[i]?.chatType === "BOOKING_3"
              ? "대기2"
              : data[i]?.chatType === "BOOKING_4"
              ? "대기3 "
              : "",
          enabled: data[i]?.enabled,
          expireTime: data[i]?.expireTime,
          lastMessage: data[i]?.lastMessage,
          lastMessageDate: data[i]?.lastMessageDate
            ? moment(data[i]?.lastMessageDate).format("YYYY-MM-DD HH:mm:ss")
            : "",
          lastMessageType: data[i]?.lastMessageType,
          memberList: data[i]?.memberList,
          open: data[i]?.open,
          owenerId: data[i]?.owenerId,
          resultCode: data[i]?.resultCode,
          resultMessage: data[i]?.resultMessage,
          subKey1: data[i]?.subKey1,
          subKey2: data[i]?.subKey2,
          teamList: data[i]?.teamList,
          totalUnreadCount: data[i]?.totalUnreadCount,
          user1Bookmark: data[i]?.user1Bookmark,
          user1FirebaseId: data[i]?.user1FirebaseId,
          user1Id: data[i]?.user1Id,
          user1Nickname: data[i]?.user1Nickname,
          user1UnreadCount: data[i]?.user1UnreadCount,
          user2Bookmark: data[i]?.user2Bookmark,
          user2FirebaseId: data[i]?.user2FirebaseId,
          user2Id: data[i]?.user2Id,
          user2Nickname: data[i]?.user2Nickname,
          user2UnreadCount: data[i]?.user2UnreadCount,
          user3Bookmark: data[i]?.user3Bookmark,
          user3FirebaseId: data[i]?.user3FirebaseId,
          user3Id: data[i]?.user3Id,
          user3Nickname: data[i]?.user3Nickname,
          user3UnreadCount: data[i]?.user3UnreadCount,
          user4Bookmark: data[i]?.user4Bookmark,
          user4FirebaseId: data[i]?.user4FirebaseId,
          user4Id: data[i]?.user4Id,
          user4Nickname: data[i]?.user4Nickname,
          user4UnreadCount: data[i]?.user4UnreadCount,
        });
      }
    }
    setGridClubData(arr);
  }, [data]);

  useEffect(() => {
    let arr = [];
    if (headerClubName?.length > 0 && headerClubNameKr?.length > 0) {
      for (let i = 0; i < headerClubName?.length; i++) {
        arr.push({
          title: headerClubNameKr[i],
          field: headerClubName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridClubColumns(arr);
    }
  }, [headerClubName, headerClubNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref, subTitle]);

  const changeSubTitle = (value) => {
    setSubTitle(value);
  };

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    채팅
                  </Col>
                </Row>
                <div style={{ padding: "10px" }} />
                <InputWrap
                  keys={keys}
                  inputKey={inputKey}
                  inputObj={inputObj}
                  setInputObj={setInputObj}
                  callApi={callApi}
                />
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px 0px" }}></div>
                    </div>
                    <div
                      id="tableWrapWidth"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: `${maxH}px`,
                      }}
                    >
                      <GridTable
                        editData={gridClubData}
                        setEditData={setGridClubData}
                        propsCloumn={gridClubColumns}
                        callApi={callApi}
                        setEditId={setEditId}
                        setIds={setIds}
                        ids={ids}
                        push="/chating/detail"
                        ref={childComponentRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(Chating);
