import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import "simplebar/src/simplebar.css";
import "./mastermanagement.scss";
import { requestDelete, requestGet, requestPost } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import GridTable from "../gridTable/GridTable";
import Slidewithfade from "../Common/Slidewithfade";
import { InputWrap } from "../Common/InputWrap";
import moment from "moment";

const ReportUser = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [clubData, setClubData] = useState(null);
  const [favoriteData, setFavoriteData] = useState(null);
  const [feedData, setFeedData] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridClubData, setGridClubData] = useState([]);
  const [gridFavoriteData, setGridFavoriteData] = useState([]);
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [headerClubName] = useState([
    "fromUserNickname",
    "reportedUserNickname",
    "date",
    "reportCount",
  ]);
  const [headerClubNameKr] = useState([
    "등록한 사람",
    "신고된 사람",
    "날짜",
    "누적 신고 횟수",
  ]);
  const [headerFavoriteName, setHeaderFavoriteName] = useState([
    "kind",
    "feedContents",
    "date",
    "thumbnail",
    "reportCount",
    "reportMessage",
    "reportState",
    "delCell",
  ]);
  const [headerFavoriteNameKr, setHeaderFavoriteNameKr] = useState([
    "종류",
    "내용",
    "날짜",
    "썸네일",
    "누적 신고 횟수",
    "사유",
    "상태",
    "삭제하기",
  ]);
  const [gridClubColumns, setGridClubColumns] = useState([]);
  const [gridFavoriteColumns, setGridFavoriteColumns] = useState([]);
  // const [userId] = useState(props.location.state.id);
  const childComponentRef = useRef();
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [maxH, setMaxH] = useState(0);
  const [subTitle, setSubTitle] = useState("userreport");

  const [infoArr, setInfoArr] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [btnArr] = useState(["userreport", "contentsreport"]);

  const [inputKey] = useState([
    {
      name: "등록한 사람",
      key: "formUser",
      type: "text",
    },
    {
      name: "신고된 사람",
      key: "reportedUser",
      type: "text",
    },
    {
      name: "날짜 from",
      key: "startDate",
      type: "date",
    },
    {
      name: "날짜 to",
      key: "endDate",
      type: "date",
    },
  ]);

  const [inputObj, setInputObj] = useState({});
  const [keys, setKeys] = useState([]);

  const [inputKey1] = useState([
    {
      name: "종류",
      key: "kind",
      type: "dropdown",
      data: [
        { key: "", value: "전체" },
        { key: "feed", value: "피드" },
        { key: "board", value: "익명 게시판" },
        { key: "club_feed", value: "클럽 피드" },
      ],
    },
    {
      name: "내용",
      key: "content",
      type: "text",
    },
    {
      name: "날짜 from",
      key: "startDate",
      type: "date",
    },
    {
      name: "날짜 to",
      key: "endDate",
      type: "date",
    },
  ]);

  const [inputObj1, setInputObj1] = useState({});
  const [keys1, setKeys1] = useState([]);

  useEffect(() => {
    let arr = [];
    inputKey?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys(arr);
  }, [inputKey]);

  useEffect(() => {
    let arr = [];
    inputKey1?.map((el, i) => {
      arr.push(el.key);
    });
    setKeys1(arr);
  }, [inputKey1]);

  const callApi = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj[isKey[i]]) {
            params.append(isKey[i], inputObj[isKey[i]]);
          }
        }
      }
      params.append("page", 0);
      params.append("size", 2147483647);
      const userResponse = await requestGet(
        `/api/admin/user/report/list?${params}`
      );
      setClubData(userResponse?.content);
      setLoading(false);
    } catch (error) {
      setClubData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  const callContentsApi = async () => {
    setLoading(true);
    let isKey = Object.keys(inputObj1);
    try {
      const params = new URLSearchParams();
      if (isKey?.length > 0) {
        for (let i = 0; i < isKey.length; i++) {
          if (inputObj1[isKey[i]]) {
            params.append(isKey[i], inputObj1[isKey[i]]);
          }
        }
      }
      params.append("page", 0);
      params.append("size", 2147483647);
      const userResponse = await requestGet(
        `/api/admin/content/report/list?${params}`
      );
      setFavoriteData(userResponse?.content);
      setLoading(false);
    } catch (error) {
      setFavoriteData([]);
      alert("잠시후 다시 실행해 주세요.");
      setLoading(false);
    }
  };

  const onDeleteApi = async (id) => {
    if (window.confirm("정말 삭제 하시겠습니까?")) {
      try {
        await requestPost(`/api/admin/content/delete/${id}`);
        alert("삭제 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        console.log(error);
        alert("삭제 실패 하였습니다.");
      }
    }
  };

  useEffect(() => {
    let arr = [];
    if (clubData?.length > 0) {
      for (let i = 0; i < clubData?.length; i++) {
        arr.push({
          ProductID: clubData[i]?.id,
          date: clubData[i]?.date
            ? moment(clubData[i]?.date).format("YYYY-MM-DD")
            : "",
          fromUserId: clubData[i]?.fromUserId,
          fromUserNickname: clubData[i]?.fromUserNickname,
          reportCount: clubData[i]?.reportCount,
          reportedUserId: clubData[i]?.reportedUserId,
          reportedUserNickname: clubData[i]?.reportedUserNickname,
        });
      }
    }
    setGridClubData(arr);
  }, [clubData]);

  useEffect(() => {
    let arr = [];
    if (headerClubName?.length > 0 && headerClubNameKr?.length > 0) {
      for (let i = 0; i < headerClubName?.length; i++) {
        arr.push({
          title: headerClubNameKr[i],
          field: headerClubName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridClubColumns(arr);
    }
  }, [headerClubName, headerClubNameKr]);

  useEffect(() => {
    let arr = [];
    if (favoriteData?.length > 0) {
      for (let i = 0; i < favoriteData?.length; i++) {
        arr.push({
          ProductID: favoriteData[i]?.id,
          clubId: favoriteData[i]?.clubId,
          contentsId: favoriteData[i]?.contentsId,
          date: favoriteData[i]?.date,
          feedContents: favoriteData[i]?.feedContents,
          fromUserId: favoriteData[i]?.fromUserId,
          fromUserNickname: favoriteData[i]?.fromUserNickname,
          kind: favoriteData[i]?.kind,
          reportCount: favoriteData[i]?.reportCount,
          reportState: favoriteData[i]?.reportState,
          reportMessage: favoriteData[i]?.reportMessage,
          reportedUserId: favoriteData[i]?.reportedUserId,
          reportedUserNickname: favoriteData[i]?.reportedUserNickname,
          targetLink: favoriteData[i]?.targetLink,
          thumbnail: favoriteData[i]?.thumbnail,
        });
      }
    }
    setGridFavoriteData(arr);
  }, [favoriteData]);

  useEffect(() => {
    let arr = [];
    if (headerFavoriteName?.length > 0 && headerFavoriteNameKr?.length > 0) {
      for (let i = 0; i < headerFavoriteName?.length; i++) {
        arr.push({
          title: headerFavoriteNameKr[i],
          field: headerFavoriteName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridFavoriteColumns(arr);
    }
  }, [headerFavoriteName, headerFavoriteNameKr]);

  useEffect(() => {
    if (topBarref.current && searchref.current && btnref.current) {
      setMaxH(
        topBarref.current?.offsetHeight -
          searchref.current?.offsetHeight -
          btnref.current?.offsetHeight -
          25
      );
    }
  }, [topBarref, searchref, btnref, subTitle]);

  const changeSubTitle = (value) => {
    setSubTitle(value);
  };

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container
        fluid
        style={{ height: "100%", maxHeight: "100%", paddingTop: "15px" }}
      >
        <div className="w-100 h-100 m-0" ref={topBarref}>
          <Row className="w-100 h-100 m-0">
            <Col
              className="col-12 px-5"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="m-0" ref={searchref}>
                <Row className="w-100 mx-0 my-0 p-0">
                  <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                    신고 관리
                  </Col>
                </Row>
                <div style={{ padding: "10px" }} />
                <Row className="w-100 p-0 m-0">
                  {btnArr?.map((btn, i) => {
                    return (
                      <Col
                        xs="auto"
                        className="d-flex align-items-end p-0"
                        key={i}
                      >
                        <Button
                          id={subTitle === btn ? "trueBtn" : "falseBtn"}
                          onClick={() => changeSubTitle(btn)}
                        >
                          {btn === "userreport"
                            ? "사용자 신고"
                            : btn === "contentsreport"
                            ? "컨텐츠 신고"
                            : ""}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
                {subTitle === "userreport" ? (
                  <InputWrap
                    keys={keys}
                    inputKey={inputKey}
                    inputObj={inputObj}
                    setInputObj={setInputObj}
                    callApi={callApi}
                  />
                ) : subTitle === "contentsreport" ? (
                  <InputWrap
                    keys={keys1}
                    inputKey={inputKey1}
                    inputObj={inputObj1}
                    setInputObj={setInputObj1}
                    callApi={callContentsApi}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="w-100 mx-0">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingBottom: "20px" }}>
                    <div ref={btnref}>
                      <div style={{ padding: "10px 0px" }}></div>
                    </div>
                    {subTitle === "userreport" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridClubData}
                          setEditData={setGridClubData}
                          propsCloumn={gridClubColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                        />
                      </div>
                    ) : subTitle === "contentsreport" ? (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridFavoriteData}
                          setEditData={setGridFavoriteData}
                          propsCloumn={gridFavoriteColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          delApi={onDeleteApi}
                          ref={childComponentRef}
                        />
                      </div>
                    ) : (
                      <div
                        id="tableWrapWidth"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: `${maxH}px`,
                        }}
                      >
                        <GridTable
                          editData={gridClubData}
                          setEditData={setGridClubData}
                          propsCloumn={gridClubColumns}
                          callApi={callApi}
                          setEditId={setEditId}
                          setIds={setIds}
                          ids={ids}
                          ref={childComponentRef}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(ReportUser);
