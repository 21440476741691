import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { ReactComponent as Bell } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-search.svg";
import { ReactComponent as Burger } from "../../assets/icons/uicons-solid-rounded/svg/fi-sr-menu-burger.svg";
import InputSearchGrid from "../gridTable/InputSearchGrid";

const SearchBarCheap = ({
  keyword,
  results,
  updateField,
  setInputValue,
  inputValue,
  type,
}) => {
  const [onSearch, setOnSearch] = useState(false);
  const [onList, setOnList] = useState(false);
  const [ids, setIds] = useState([]);
  const [valueIds, setValueIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [editValueId, setEditValueId] = useState(null);
  const [inputText, setInputText] = useState({});
  const [inputPasteValue, setInputPasteValue] = useState("");
  const [headerName, setHeaderName] = useState(["selected"]);
  const [headerNameKr, setHeaderNameKr] = useState(["selected"]);
  const [headerValueName, setHeaderValueName] = useState(["selected", "value"]);
  const [headerValueNameKr, setHeaderValueNameKr] = useState([
    "selected",
    "Value",
  ]);
  const [gridColumns, setGridColumns] = useState([]);
  const [gridValueColumns, setGridValueColumns] = useState([]);
  const [editData, setEditData] = useState([]);
  const [valueData, setValueData] = useState([]);
  const [splitData, setSplitData] = useState([]);
  const [searchColumns] = useState([]);
  const [textValue, setTextValue] = useState({});
  const arr = results;

  useEffect(() => {
    if (type === "sku") {
      setHeaderName([...headerName, "sku"]);
      setHeaderNameKr([...headerNameKr, "SKU"]);
    } else if (type === "nameEn") {
      setHeaderName([...headerName, "name"]);
      setHeaderNameKr([...headerNameKr, "Name"]);
    } else if (type === "nameKr") {
      setHeaderName([...headerName, "nameKr"]);
      setHeaderNameKr([...headerNameKr, "Name"]);
    } else if (type === "soldTo") {
      setHeaderName([...headerName, "soldTo"]);
      setHeaderNameKr([...headerNameKr, "Name"]);
    } else if (type === "sales") {
      setHeaderName([...headerName, "sales"]);
      setHeaderNameKr([...headerNameKr, "Name"]);
    } else if (type === "shipTo") {
      setHeaderName([...headerName, "shipTo"]);
      setHeaderNameKr([...headerNameKr, "Name"]);
    } else if (type === "soldToID") {
      setHeaderName([...headerName, "soldToID"]);
      setHeaderNameKr([...headerNameKr, "Name"]);
    } else if (type === "shipToID") {
      setHeaderName([...headerName, "shipToID"]);
      setHeaderNameKr([...headerNameKr, "Name"]);
    }
  }, [type]);

  useEffect(() => {
    let arr = [];
    if (results) {
      if (type === "sku") {
        for (let i = 0; i < results.length; i++) {
          arr.push({
            ProductID: results[i].id,
            sku: results[i].sku,
            selected: false,
          });
        }
      } else if (type === "nameEn") {
        for (let i = 0; i < results.length; i++) {
          arr.push({
            ProductID: results[i].id,
            name: results[i].nameEn,
            selected: false,
          });
        }
      } else if (type === "nameKr") {
        for (let i = 0; i < results.length; i++) {
          arr.push({
            ProductID: results[i].id,
            nameKr: results[i].nameKr,
            selected: false,
          });
        }
      } else if (type === "soldTo") {
        for (let i = 0; i < results.length; i++) {
          arr.push({
            ProductID: results[i].id,
            soldTo: results[i].nickname,
            selected: false,
          });
        }
      } else if (type === "soldToID") {
        for (let i = 0; i < results.length; i++) {
          arr.push({
            ProductID: results[i].id,
            soldToID: results[i].username,
            selected: false,
          });
        }
      } else if (type === "sales") {
        for (let i = 0; i < results.length; i++) {
          arr.push({
            ProductID: results[i].zsort,
            sales: results[i].orgtx,
            selected: false,
          });
        }
      } else if (type === "shipTo") {
        for (let i = 0; i < results.length; i++) {
          arr.push({
            ProductID: results[i].id,
            shipTo: results[i].nickname,
            selected: false,
          });
        }
      } else if (type === "shipToID") {
        for (let i = 0; i < results.length; i++) {
          arr.push({
            ProductID: results[i].id,
            shipToID: results[i].username,
            selected: false,
          });
        }
      }
    }
    setEditData(arr);
  }, [results]);

  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          minWidth: 100,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);
  useEffect(() => {
    let arr = [];
    if (headerValueName?.length > 0 && headerValueNameKr?.length > 0) {
      for (let i = 0; i < headerValueName?.length; i++) {
        arr.push({
          title: headerValueNameKr[i],
          field: headerValueName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          minWidth: 100,
        });
      }
      setGridValueColumns(arr);
    }
  }, [headerValueName, headerValueNameKr]);

  const onText = (e) => {
    if (e.target.value === "") {
      updateField("keyword", e.target.value);
    } else {
      updateField("keyword", e.target.value);
    }
  };

  const addValue = () => {
    let obj = inputValue;
    let arr = [];
    if (inputValue[type]) {
      arr.push(...inputValue[type].split(","));
    }
    editData.map((item) => {
      if (ids.includes(String(item.ProductID))) {
        arr.push(item[type]);
      }
    });
    let setArr = [...new Set(arr)];
    obj[type] = setArr.join();
    setInputValue(obj);
    setOnSearch(!onSearch);
    updateField("keyword", "");
  };
  const delValue = () => {
    let obj = inputValue;
    let check = valueData;
    if (valueIds?.length > 0) {
      let arr = [];
      for (let i = 0; i < valueData.length; i++) {
        if (!valueIds.includes(String(valueData[i].ProductID))) {
          arr.push(valueData[i].value);
          check[i].selected = false;
        }
      }
      obj[type] = arr.join();
      setInputValue(obj);
      setValueData(check);
      updateField("keyword", "");
    }
  };

  const inputFun = () => {
    let obj = inputValue;
    console.log(obj);
    let setArr = [];
    if (inputValue[type]) {
      let arr = inputValue[type]?.split(",");
      console.log(inputValue[type]);
      console.log(arr);
      setArr = [...new Set(arr)];
    } else {
      setArr = [];
    }
    if (setArr) {
      setSplitData(setArr);
    } else {
      setSplitData([]);
    }
    obj[type] = setArr.join();
    setInputValue(obj);
    setTextValue(obj[type]);
  };

  useEffect(() => {
    inputFun();
  }, [inputValue[type], inputValue]);

  useEffect(() => {
    if (splitData.length > 0) {
      let arr = [];
      for (let i = 0; i < splitData.length; i++) {
        arr.push({
          ProductID: splitData[i],
          value: splitData[i],
          selected: false,
        });
      }
      setValueData(arr);
    } else {
      setValueData([]);
    }
  }, [splitData]);

  const openSearch = () => {
    setOnSearch(!onSearch);
    setOnList(false);
  };

  const openList = () => {
    setOnSearch(false);
    setOnList(!onList);
  };
  const onFirstNamePaste = (e) => {
    e.preventDefault();
    var text = e.clipboardData.getData("Text");
    var value = text
      .replace(/\n/g, ",")
      .replace(/\r/g, ",")
      .replace(/,,/g, ",");
    if (inputValue[type]) {
      let arr = inputValue[type];
      arr = arr + "," + value;
      setInputPasteValue(arr);
    } else {
      setInputPasteValue(value);
    }
  };

  useEffect(() => {
    let obj = inputValue;

    if (inputPasteValue) {
      obj[type] = inputPasteValue;
      console.log(obj);
      setInputValue(obj);
      inputFun();
    }
  }, [inputPasteValue]);

  const onchangeinput = (e) => {
    e.preventDefault();
    let obj = inputValue;
    obj[type] = e.target.value;
    setTextValue(e.target.value);
    // console.log(obj);
    setInputValue(obj);
  };
  return (
    <Row>
      <Col>
        <InputGroup>
          <Input
            className="form-control"
            placeholder="Search"
            value={inputValue[type]}
            onChange={(e) => onchangeinput(e)}
            onPaste={(e) => onFirstNamePaste(e)}
          />
          <InputGroupText style={{ background: "#fff" }}>
            <Burger
              style={{
                width: "20px",
                height: "20px",
                fill: "#000",
                cursor: "pointer",
                zIndex: 1,
              }}
              onClick={() => openList()}
            />
          </InputGroupText>
          <InputGroupText
            style={{ background: "#fff" }}
            onClick={() => openSearch()}
          >
            <Bell
              style={{
                width: "20px",
                height: "20px",
                fill: "#000",
                cursor: "pointer",
              }}
            />
          </InputGroupText>
        </InputGroup>
        {onSearch ? (
          <Row
            className="w-100"
            style={{
              position: "absolute",
              zIndex: "100",
            }}
          >
            <Col>
              <div
                className="search-results w-100"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #ced4da",
                  borderRadius: "5px",
                }}
              >
                <Input
                  className="form-control"
                  placeholder="Search"
                  value={keyword || ""}
                  onChange={onText}
                />
                <InputSearchGrid
                  editData={editData}
                  setEditData={setEditData}
                  propsCloumn={gridColumns}
                  searchColumns={searchColumns}
                  setEditId={setEditId}
                  setIds={setIds}
                />
                <Button
                  style={{
                    backgroundColor: "#FF5913",
                    border: "none",
                    width: "100%",
                  }}
                  onClick={() => addValue()}
                >
                  추가하기
                </Button>
              </div>
            </Col>
          </Row>
        ) : null}
        {onList ? (
          <Row
            className="w-100"
            style={{
              position: "absolute",
              zIndex: "100",
            }}
          >
            <Col>
              <div
                className="search-results w-100"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #ced4da",
                  borderRadius: "5px",
                }}
              >
                <InputSearchGrid
                  editData={valueData}
                  setEditData={setValueData}
                  propsCloumn={gridValueColumns}
                  setInputText={setInputText}
                  searchColumns={searchColumns}
                  setEditId={setEditValueId}
                  setIds={setValueIds}
                />
                <Button
                  style={{
                    backgroundColor: "#FF5913",
                    border: "none",
                    width: "100%",
                  }}
                  onClick={() => delValue()}
                >
                  제거하기
                </Button>
              </div>
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
  );
};

export default SearchBarCheap;
