import * as React from "react";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import { apiUrl } from "../../apis/request";
import axios from "axios";
import { getCookie } from "../../cookie";
export const DefaultCell = (props) => {
  const { dataItem, push, delApi, callApi, deleteapi, addapi } = props;
  const field = props.field || "";
  const fdgrid = React.useRef(null);
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  const history = useHistory();

  const combinedStyle = {
    ...(push ? { cursor: "pointer" } : {}),
    ...props.style,
  };

  const onHistory = () => {
    if (push) {
      if (push === "/banner/detail" || push === "/notice/detail") {
        history.push({
          pathname: push,
          state: {
            data: dataItem,
          },
        });
      } else if (push === "/profile/detail") {
        history.push({
          pathname: push,
          state: {
            data: dataItem.data,
          },
        });
      } else if (push === "/club/detail") {
        history.push({
          pathname: push,
          state: {
            id: dataItem.ProductID,
          },
        });
      } else if (push === "/chating/detail") {
        history.push({
          pathname: push,
          state: {
            id: dataItem.chatCode,
          },
        });
      } else if (push === "/feed/detail") {
        history.push({
          pathname: push,
          state: {
            data: dataItem.data,
          },
        });
      } else {
        history.push({
          pathname: push,
          state: {
            id: dataItem.userId,
          },
        });
      }
    }
  };

  const onChatDown = async () => {
    // try {
    //   const res = await requestGet(
    //     `/api/admin/chatting/message/${dataItem.chatCode}/down`
    //   );
    //   window.open(res);
    // } catch (error) {
    //   console.log(error);
    //   alert("다운 실패 하였습니다.");
    // }
    const token = getCookie("golfToken");
    axios({
      url: `${apiUrl}/api/admin/chatting/message/${dataItem.chatCode}/down`, // 파일 다운로드 요청 URL
      method: "GET", // 혹은 'POST'
      responseType: "blob", // 응답 데이터 타입 정의
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
      // 특정 타입을 정의해야 경우에는 옵션을 사용해 MIME 유형을 정의 할 수 있습니다.
      // const blob = new Blob([this.content], {type: 'text/plain'})
      // blob을 사용해 객체 URL을 생성합니다.
      const fileObjectUrl = window.URL.createObjectURL(response.data);

      // blob 객체 URL을 설정할 링크를 만듭니다.
      const link = document.createElement("a");
      link.href = fileObjectUrl;
      link.style.display = "none";
      // 다운로드 파일 이름을 지정 할 수 있습니다.
      // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
      // link.download = extractDownloadFilename(response);

      // 다운로드 파일 이름을 추출하는 함수

      // 다운로드 파일의 이름은 직접 지정 할 수 있습니다.
      link.download = "채팅 내역.csv";

      // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행시킵니다.
      document.body.appendChild(link);
      link.click();
      link.remove();
      // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
      window.URL.revokeObjectURL(fileObjectUrl);
    });
  };

  return (
    <td
      style={combinedStyle}
      className={props.className}
      onDoubleClick={() => onHistory()}
    >
      {field === "thumbnail" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {dataValue ? (
            <img src={dataValue} alt="사진" style={{ maxWidth: "150px" }} />
          ) : (
            ""
          )}
        </div>
      ) : field === "addBtn" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            id="trueBtn"
            onClick={() =>
              addapi(dataItem.ProductID, {
                userId: dataItem.userId,
                gender: dataItem.gender,
                registedAt: dataItem.registerDate,
              })
            }
          >
            등록하기
          </Button>
        </div>
      ) : field === "delBtn" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            id="trueBtn"
            onClick={() =>
              deleteapi(dataItem.ProductID, {
                userId: dataItem.userId,
                gender: dataItem.gender,
                registedAt: dataItem.registerDate,
              })
            }
          >
            삭제하기
          </Button>
        </div>
      ) : field === "delCell" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button id="trueBtn" onClick={() => delApi(dataItem.ProductID)}>
            삭제하기
          </Button>
        </div>
      ) : field === "chatdown" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button id="trueBtn" onClick={() => onChatDown()}>
            다운로드
          </Button>
        </div>
      ) : (
        <span ref={fdgrid}>{dataValue === "null" ? "" : dataValue}</span>
      )}
    </td>
  );
};
