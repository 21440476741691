import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Badge } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";
import { ReactComponent as Client } from "../../assets/icons/new_icon/client.svg";
import { ReactComponent as Order } from "../../assets/icons/new_icon/order.svg";
import { ReactComponent as Product } from "../../assets/icons/new_icon/product.svg";
import { ReactComponent as Calculator } from "../../assets/icons/new_icon/calculator.svg";
import { ReactComponent as Criteria } from "../../assets/icons/new_icon/criteria.svg";
import { ReactComponent as Graph } from "../../assets/icons/new_icon/graph.svg";
import { ReactComponent as Order2 } from "../../assets/icons/new_icon/order_2.svg";
import { ReactComponent as Security } from "../../assets/icons/new_icon/security.svg";
import { ReactComponent as Stock } from "../../assets/icons/new_icon/stock.svg";
import { ReactComponent as System } from "../../assets/icons/new_icon/system.svg";
import SidebarRun from "./Sidebar.run";
// import SidebarUserBlock from './SidebarUserBlock';

import { CustomMenu } from "../../Menu.js";

let Menu = CustomMenu;

/** Component to display headings on sidebar */

const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>{item.heading}</span>
  </li>
);

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
  <li
    className={isActive ? "active" : ""}
    style={
      item.name === "팝업 업로드" ||
      item.name === "카탈로그 업로드" ||
      item.name === "뉴스레터 업로드" ||
      item.name === "인증서 업로드" ||
      item.name === "공지사항 디테일"
        ? { display: "none" }
        : null
    }
  >
    <Link
      to={item.path}
      title={item.name}
      id={item.pgmid}
      onClick={() => localStorage.setItem("pmgId", item.pgmid)}
      className="nav-item d-flex"
    >
      {item.label && (
        <Badge tag="div" className="float-right" color={item.label.color}>
          {item.label.value}
        </Badge>
      )}
      {item.icon === "Product" ? (
        <Product className="sideicon" />
      ) : item.icon === "Calculator" ? (
        <Calculator className="sideicon" />
      ) : item.icon === "Client" ? (
        <Client className="sideicon" />
      ) : item.icon === "Criteria" ? (
        <Criteria className="sideicon" />
      ) : item.icon === "Graph" ? (
        <Graph className="sideicon" />
      ) : item.icon === "Order" ? (
        <Order className="sideicon" />
      ) : item.icon === "Order2" ? (
        <Order2 className="sideicon" />
      ) : item.icon === "Security" ? (
        <Security className="sideicon" />
      ) : item.icon === "Stock" ? (
        <Stock className="sideicon" />
      ) : item.icon === "System" ? (
        <System className="sideicon" />
      ) : (
        <em className={item.icon}></em>
      )}
      <span style={{ width: "100%", display: "block", whiteSpace: "pre-wrap" }}>
        {item.name}
      </span>
    </Link>
  </li>
);

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
  <li className={isOpen ? "active base-active" : isActive ? "active" : ""}>
    <div className="nav-item base-arrow" onClick={handler}>
      {/* {item.label && (
        <Badge tag="div" className="float-right" color={item.label.color}>
          {item.label.value}
        </Badge>
      )} */}
      {item.icon === "Product" ? (
        <Product className="sideicon" />
      ) : item.icon === "Calculator" ? (
        <Calculator className="sideicon" />
      ) : item.icon === "Client" ? (
        <Client className="sideicon" />
      ) : item.icon === "Criteria" ? (
        <Criteria className="sideicon" />
      ) : item.icon === "Graph" ? (
        <Graph className="sideicon" />
      ) : item.icon === "Order" ? (
        <Order className="sideicon" />
      ) : item.icon === "Order2" ? (
        <Order2 className="sideicon" />
      ) : item.icon === "Security" ? (
        <Security className="sideicon" />
      ) : item.icon === "Stock" ? (
        <Stock className="sideicon" />
      ) : item.icon === "System" ? (
        <System className="sideicon" />
      ) : (
        <em className={item.icon}></em>
      )}
      <span>{item.name}</span>
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className="sidebar-nav sidebar-subnav">
        {children}
      </ul>
    </Collapse>
  </li>
);

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">{item.name}</li>
);

class Sidebar extends Component {
  state = {
    collapse: {},
  };

  componentDidMount() {
    // pass navigator to access router api
    SidebarRun(this.navigator, this.closeSidebar);
    // prepare the flags to handle menu collapsed states
    this.buildCollapseList();

    // Listen for routes changes in order to hide the sidebar on mobile
    this.props.history.listen(this.closeSidebar);
  }

  closeSidebar = () => {
    this.props.actions.changeSetting("asideToggled", false);
  };

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  buildCollapseList = () => {
    let collapse = {};
    Menu?.filter(({ heading }) => !heading).forEach(
      ({ name, path, submenu }) => {
        collapse[name] = this.routeActive(
          submenu ? submenu.map(({ path }) => path) : path
        );
      }
    );
    this.setState({ collapse });
  };

  navigator = (route) => {
    this.props.history.push(route.replace("#", "")); // remove '#' in case of use HashRouter
  };

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some((p) => this.props.location.pathname === p);
  }

  routeActives(paths) {
    return paths === this.props.location.pathname;
  }

  toggleItemCollapse(stateName) {
    // eslint-disable-next-line
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName)
        this.setState({
          collapse: {
            [c]: false,
          },
        });
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName],
      },
    });
  }

  getSubRoutes = (item) => item.submenu.map(({ path }) => path);

  /** map menu config to string to determine which element to render */
  itemType = (item) => {
    if (item.heading) return "heading";
    if (!item.submenu) return "menu";
    if (item.submenu) return "submenu";
  };

  render() {
    return (
      <aside className="aside-container" id="asidecontain">
        {/* START Sidebar (left) */}
        <div className="aside-inner">
          <nav
            data-sidebar-anyclick-close=""
            className="sidebar"
            style={{ paddingTop: "10px" }}
          >
            {/* START sidebar nav */}
            <ul className="sidebar-nav">
              {/* START user info */}
              <li className="has-user-block">{/* <SidebarUserBlock/> */}</li>
              {/* END user info */}

              {/* Iterates over all sidebar items */}
              {Menu?.map((item, i) => {
                // heading
                if (this.itemType(item) === "heading")
                  return <SidebarItemHeader item={item} key={i} />;
                else {
                  if (this.itemType(item) === "menu")
                    return (
                      <SidebarItem
                        isActive={this.routeActive(item.path)}
                        item={item}
                        key={i}
                      />
                    );
                  if (this.itemType(item) === "submenu")
                    return [
                      <SidebarSubItem
                        item={item}
                        isOpen={this.state.collapse[item.name]}
                        handler={this.toggleItemCollapse.bind(this, item.name)}
                        isActive={this.routeActive(this.getSubRoutes(item))}
                        key={i}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map((subitem, i) => (
                          <SidebarItem
                            key={i}
                            item={subitem}
                            isActive={this.routeActives(subitem.path)}
                          />
                        ))}
                      </SidebarSubItem>,
                    ];
                }
                return null; // unrecognized item
              })}
            </ul>
            {/* END sidebar nav */}
          </nav>
        </div>
        {/* END Sidebar (left) */}
      </aside>
    );
  }
}

Sidebar.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("translations")(withRouter(Sidebar)));
