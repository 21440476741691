import React, { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import "./mastermanagement.scss";
import { formPost, requestPost } from "../../apis/request";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import GridTable from "../gridTable/GridTable";
import GridTableNoPage from "../gridTable/GridTableNoPage";

const NoticeUpload = () => {
  const [postfiles, setPostfiles] = useState([]);
  const [postfileIds, setPostfilesIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [date, setDate] = useState("");
  const [gridData, setGridData] = useState([]);
  const [url, setUrl] = useState("");
  const [ids, setIds] = useState([]);
  const [editId, setEditId] = useState(null);
  const [push, setPush] = useState(false);
  const [popup, setPopup] = useState(false);
  const history = useHistory();
  const [headerName] = useState(["name", "size"]);
  const [headerNameKr] = useState(["File Name", "File Size"]);
  const [gridColumns, setGridColumns] = useState([]);
  const [inputKey, setInputKey] = useState(Date.now());
  const [inputImgKey, setInputImgKey] = useState(
    moment().format("YYYYMMDDHHmmss")
  );
  const userId = localStorage.getItem("userId");
  const childComponentRef = useRef();

  const onFileHandler = (e) => {
    let arr = [];
    e.stopPropagation();
    let file = e.target.files;
    console.log(file);
    for (let i = 0; i < file.length; i++) {
      if (!postfileIds.includes(file[i].name)) {
        arr.push(file[i]);
      } else {
      }
    }
    setPostfiles(arr);
  };

  const onFileApi = async (e) => {
    if (window.confirm("등록 하시겠습니까?")) {
      try {
        setLoading(true);
        const params = new URLSearchParams();
        params.append("title", title);
        params.append("contents", contents);
        params.append("url", url);
        params.append("pushSend", push);
        params.append("popup", popup);
        let data = params;
        let res = await requestPost("/api/admin/notice/write", data);
        if (res.id) {
          const param = new URLSearchParams();
          param.append("userId", userId);
          param.append("boardId", res.id);
          if (postfiles.length > 0) {
            var bodyFormData = new FormData();
            bodyFormData.append("file", postfiles[0]);
            await formPost(`/uploadFile/NOTICE/IMAGE?${param}`, bodyFormData);
          }
        }
        setLoading(false);
        alert("등록하였습니다.");
        history.goBack();
      } catch (error) {
        setLoading(false);
        alert("등록 실패 하였습니다.");
      }
    }
  };

  useEffect(() => {
    let ids = [];
    let arr = [];
    if (postfiles.length > 0) {
      for (const el of postfiles) {
        ids.push(el.name);
        arr.push({
          name: el.name,
          size: el.size,
        });
      }
      setPostfilesIds(ids);
      setGridData(arr);
    }
  }, [postfiles]);

  const removelistImg = (name) => {
    setPostfiles([]);
    setPostfilesIds([]);
    setInputKey(Date.now());
  };

  const onTitle = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const onContents = (e) => {
    e.preventDefault();
    setContents(e.target.value);
  };

  const handleDate = (e) => {
    setDate(moment(e.target.value).format("YYYYMMDD"));
  };

  const onURL = (e) => {
    e.preventDefault();
    setUrl(e.target.value);
  };
  useEffect(() => {
    let arr = [];
    if (headerName?.length > 0 && headerNameKr?.length > 0) {
      for (let i = 0; i < headerName?.length; i++) {
        arr.push({
          title: headerNameKr[i],
          field: headerName[i],
          locked: false,
          hidden: false,
          show: true,
          filter: "text",
          width: 150,
        });
      }
      setGridColumns(arr);
    }
  }, [headerName, headerNameKr]);

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <Container fluid>
        <Row className="w-100 m-0 d-flex justify-content-center">
          <Col className="col-12 px-5">
            <Row className="m-0">
              <Row className="w-100 mx-0 my-0 p-0">
                <Col className="MasterTitle w-100 m-0 p-0" id="headerTilteID">
                  공지 등록
                </Col>
              </Row>
              <div style={{ padding: "10px" }} />
            </Row>
            <Row className="w-100 m-0 mb-4">
              <Col>
                <Row>
                  <Col className="p-0">
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          제목
                          <Input type="text" value={title} onChange={onTitle} />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          내용
                          <Input
                            type="textarea"
                            rows="3"
                            style={{ resize: "none" }}
                            value={contents}
                            onChange={onContents}
                          />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          URL
                          <Input type="text" value={url} onChange={onURL} />
                        </Label>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          푸시 보내기
                          <Input
                            type="checkbox"
                            style={{ marginLeft: "10px" }}
                            checked={push}
                            onChange={() => setPush(!push)}
                          />
                        </Label>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <Label
                          className="w-100"
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          팝업 공지
                          <Input
                            type="checkbox"
                            style={{ marginLeft: "10px" }}
                            checked={popup}
                            onChange={() => setPopup(!popup)}
                          />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ display: "flex", flexDirection: "column" }}>
                        <Label
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          이미지
                        </Label>
                        <div>
                          <Label className="fileAddName">
                            <Input
                              type="file"
                              id="image"
                              key={inputKey}
                              onChange={onFileHandler}
                            />
                            <span
                              className="btn acceptBtn"
                              style={{
                                padding: "0.47rem 0.75rem",
                                borderRadius: "5px",
                                color: "#fff",
                              }}
                            >
                              Select files
                            </span>
                          </Label>
                          <Button
                            className="dangerBtn ms-2"
                            onClick={() => removelistImg()}
                          >
                            remove
                          </Button>
                        </div>
                      </Col>
                      {postfiles.length > 0 ? (
                        <div className="mx-0 mt-3">
                          <div style={{ display: "flex" }}>
                            <div>
                              <GridTableNoPage
                                editData={gridData}
                                setEditData={setGridData}
                                propsCloumn={gridColumns}
                                setEditId={setEditId}
                                setIds={setIds}
                                ids={ids}
                                ref={childComponentRef}
                                shop
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        width: "auto",
                        marginBottom: "10px",
                      }}
                    >
                      <Col xs="auto" className="d-flex align-items-end">
                        <Button
                          className="acceptBtn"
                          onClick={() => onFileApi()}
                        >
                          등록하기
                        </Button>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(NoticeUpload);
