//개발

import "./pages/header/sidebar.scss";
const CustomMenu = [
  {
    name: "회원 목록",
    icon: "Client",
    path: "/user/list",
  },
  {
    name: "클럽 목록",
    icon: "Client",
    path: "/club/list",
  },
  {
    name: "프로필 승인 대기",
    icon: "Order",
    path: "/profile",
  },
  {
    name: "메인화면 제어",
    icon: "Criteria",
    path: "/main/con",
  },
  {
    name: "버전 관리",
    icon: "System",
    path: "/version/con",
  },
  {
    name: "피드 목록",
    icon: "Order",
    path: "/feed/list",
  },
  {
    name: "배너 목록",
    icon: "Order",
    path: "/banner/list",
  },
  {
    name: "공지 목록",
    icon: "Order",
    path: "/notice/list",
  },
  {
    name: "사용자 위치",
    icon: "Client",
    path: "/user/map",
  },
  {
    name: "푸시 메세지 보내기",
    icon: "Client",
    path: "/push/post",
  },
  {
    name: "시스템 점검 등록",
    icon: "Client",
    path: "/service",
  },
  {
    name: "추천 사용자",
    icon: "Client",
    path: "/user/recommand",
  },
  {
    name: "신고 관리",
    icon: "Client",
    path: "/user/report",
  },
  {
    name: "채팅",
    icon: "Client",
    path: "/chating",
  },
  {
    name: "추천 검색어",
    icon: "Client",
    path: "/recommends/keyword",
  },
];

export { CustomMenu };
